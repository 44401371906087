import React, { useMemo } from 'react';

import { useSelector } from 'react-redux';
import { RiMapPinLine, RiNavigationLine, RiPencilLine } from 'react-icons/ri';

import { AddressModal } from '~/components';
import { useAddress, useTranslator } from '~/hooks';
import { IApplicationState } from '~/redux-tools/store';
import { DeliveryPricingEnum } from '~/interfaces/enums';
import { convertToCurrency, mountAddress } from '~/utils';
import { Address, IDeliveryFeeDynamic, IDeliveryFeeNeighborhood, IDeliveryFeeStatic } from '~/interfaces/general';

import * as S from './styles';

interface AddressDeliveryInfoProps {
  address?: Address;
  canEdit?: boolean;
  isModalVisible?: boolean;
  setIsModalVisible?: (status: boolean) => void;
  deliveryFee?: IDeliveryFeeDynamic | IDeliveryFeeStatic | IDeliveryFeeNeighborhood;
}

const AddressDeliveryInfo = ({
  address,
  deliveryFee,
  canEdit = false,
  setIsModalVisible,
  isModalVisible = false
}: AddressDeliveryInfoProps): JSX.Element => {
  const { settings } = useSelector((state: IApplicationState) => state.establishment);

  const { isStatic } = useAddress();
  const { getTranslation } = useTranslator();

  const currentPricing = useMemo(() => {
    let pricing;

    switch (deliveryFee?.pricing) {
      case DeliveryPricingEnum.free:
        pricing = getTranslation('general.free');
        break;
      case DeliveryPricingEnum.ask:
        pricing = getTranslation('address.feeToBeAgreed');
        break;
      case DeliveryPricingEnum.outside:
        pricing = false;
        break;
      case DeliveryPricingEnum.notFound:
        pricing = DeliveryPricingEnum.notFound;
        break;
      default:
        pricing = deliveryFee?.pricing ? `${convertToCurrency(Number(deliveryFee?.pricing))}` : '';
        break;
    }

    return pricing;
  }, [deliveryFee?.pricing, getTranslation]);

  const renderButtonResume = useMemo(() => {
    if (canEdit) {
      const selectAddressText = isStatic
        ? getTranslation('address.selectAnAddressToDelivery')
        : `${getTranslation('address.selectAnAddress')} ${getTranslation('address.selectAnAddressDescription')}`;

      if (!address) {
        return (
          <S.ActionWrapper data-test="btn-select-address" onClick={(): void => setIsModalVisible?.(true)}>
            <RiNavigationLine size={20} />

            <p className="action">{selectAddressText}</p>
          </S.ActionWrapper>
        );
      }

      if (!currentPricing || currentPricing === DeliveryPricingEnum.notFound) {
        return (
          <S.ActionWrapper $hasEdit onClick={(): void => setIsModalVisible?.(true)}>
            <span role="img" className="emoji" aria-label="sad emoji">
              😢
            </span>

            <div className="error">
              <p className="title">
                {!currentPricing ? getTranslation('address.tooFarMessage') : getTranslation('address.addressNotFound')}
              </p>

              <small className="description">
                {!currentPricing
                  ? getTranslation('address.tooFarDescription')
                  : `${getTranslation('general.contactStore')}!`}
              </small>
            </div>

            <RiPencilLine size={24} />
          </S.ActionWrapper>
        );
      }
    }

    return (
      <S.ActionWrapper $hasEdit={canEdit} onClick={(): void => setIsModalVisible?.(true)}>
        <RiMapPinLine size={20} />

        <p className="description">
          {address
            ? mountAddress({ address, withCep: true, withStreet: true, settings })
            : getTranslation('address.noAddress')}
        </p>

        {canEdit && <RiPencilLine size={24} />}
      </S.ActionWrapper>
    );
  }, [canEdit, address, settings, getTranslation, isStatic, currentPricing, setIsModalVisible]);

  return (
    <S.Container
      $canEdit={canEdit}
      $hasError={deliveryFee && (!currentPricing || currentPricing === DeliveryPricingEnum.notFound)}
    >
      {renderButtonResume}

      <AddressModal
        isModalVisible={isModalVisible}
        setIsModalVisible={(isModalVisible): void => setIsModalVisible?.(isModalVisible)}
      />
    </S.Container>
  );
};

export default AddressDeliveryInfo;
