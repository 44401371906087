import { useCallback, useContext, useMemo } from 'react';

import { useSelector } from 'react-redux';

import { useTranslator } from '~/hooks';
import Counter from '~/components/Counter';
import { convertToCurrency } from '~/utils';
import Modal from '~/components/Modal/Modal';
import Button from '~/components/Button/Button';
import { IApplicationState } from '~/redux-tools/store';
import InputCurrency from '~/components/Input/InputCurrency';
import CheckboxWithLabel from '~/components/CheckboxWithLabel';
import { ModalCloseButtonTypesEnum, SelectedPaymentModeEnum } from '~/interfaces/enums';
import { PricesProps, CheckoutContext as MyTabContext } from '~/pages/myTabPaymentDetails';

import * as S from './styles';

interface PaymentModeModalProps {
  showModal: boolean;
  onClose: () => void;
  prices: PricesProps;
  confirmModal: () => void;
  mode: SelectedPaymentModeEnum;
  isCheckedServiceCharge: boolean;
  isServiceChargeEnabled: boolean;
  handleServiceCharge: () => void;
  calculateServiceCharge?: (value: number) => number;
}

const PaymentModeModal: (props: PaymentModeModalProps) => JSX.Element = ({
  prices,
  onClose,
  showModal,
  confirmModal,
  handleServiceCharge,
  isCheckedServiceCharge,
  isServiceChargeEnabled,
  mode = SelectedPaymentModeEnum.singleValue
}) => {
  const { theme } = useSelector((state: IApplicationState) => state.theme);

  const { getTranslation } = useTranslator();

  const {
    singlePaymentValue,
    setSinglePaymentValue,
    numberPeopleToDivision,
    setNumberPeopleToDivision,
    hasSinglePaymentValueInputError,
    setHasSinglePaymentValueInputError
  } = useContext(MyTabContext);

  const handleSingleValue = useCallback(
    (params: { value: number; maskedValue: string }) => {
      setSinglePaymentValue(params.value);
    },
    [setSinglePaymentValue]
  );

  const isConfirmButtonDisabled: boolean =
    (mode === SelectedPaymentModeEnum.accountDivision && !!numberPeopleToDivision && numberPeopleToDivision <= 1) ||
    (mode === SelectedPaymentModeEnum.singleValue && prices.totalToPay <= 0);

  const shouldDisplayMissingToBePaidTag: boolean =
    mode === SelectedPaymentModeEnum.singleValue &&
    !!prices.missingToBePaid &&
    prices.missingToBePaid > 0 &&
    prices.valuePaid > 0;

  const renderPaymentSummary = useMemo(() => {
    return (
      <S.PaymentSummaryWrapper>
        <S.PaymentSummaryItem>
          <S.StrongText>
            {mode === SelectedPaymentModeEnum.accountDivision
              ? getTranslation('myTab.pricePerPerson')
              : getTranslation('myTab.amountToPay')}
          </S.StrongText>

          <S.StrongText>{convertToCurrency(prices.totalToPay)}</S.StrongText>
        </S.PaymentSummaryItem>

        {isServiceChargeEnabled && (
          <S.PaymentSummaryItem isDisabled={!isCheckedServiceCharge} onClick={handleServiceCharge}>
            <S.RegularText>
              <CheckboxWithLabel
                disabled={false}
                checked={isCheckedServiceCharge}
                handleChange={handleServiceCharge}
                label={getTranslation('general.serviceTax')}
                aria-label={getTranslation('myTab.checkboxServiceCharge')}
              />
            </S.RegularText>

            <S.RegularText>{`+ ${convertToCurrency(prices.serviceTax || 0)}`}</S.RegularText>
          </S.PaymentSummaryItem>
        )}
      </S.PaymentSummaryWrapper>
    );
  }, [
    mode,
    getTranslation,
    prices.serviceTax,
    prices.totalToPay,
    handleServiceCharge,
    isCheckedServiceCharge,
    isServiceChargeEnabled
  ]);

  return (
    <Modal
      isSmall
      onClose={onClose}
      isShow={showModal}
      closeButton={{ color: theme.colors.textColor, type: ModalCloseButtonTypesEnum.times }}
    >
      <S.ModalContent>
        <S.StrongText>
          {mode === SelectedPaymentModeEnum.singleValue
            ? getTranslation('myTab.enterValue')
            : getTranslation('myTab.divideByPeaople')}
        </S.StrongText>

        <S.RegularText>{getTranslation('myTab.howMuchYouWantToPay')}</S.RegularText>

        <S.TagWrapper>
          <S.Tag>
            {getTranslation('myTab.totalTable')}: {convertToCurrency(prices.subtotal)}
          </S.Tag>

          {shouldDisplayMissingToBePaidTag && (
            <S.Tag color={theme.colors.error}>
              {getTranslation('myTab.missingToPay')}:
              {!!prices.missingToBePaid && convertToCurrency(prices.missingToBePaid)}
            </S.Tag>
          )}
        </S.TagWrapper>

        {mode === SelectedPaymentModeEnum.singleValue ? (
          <>
            <S.InputCurrencyWrapper>
              <InputCurrency
                required
                hasError={hasSinglePaymentValueInputError}
                value={convertToCurrency(singlePaymentValue)}
                onChange={(value): void => {
                  handleSingleValue(value);
                  setHasSinglePaymentValueInputError(false);
                }}
              />
            </S.InputCurrencyWrapper>

            {renderPaymentSummary}
          </>
        ) : (
          <>
            <Counter
              value={numberPeopleToDivision || 0}
              disabledSubtractItem={numberPeopleToDivision === 1}
              handleAddItem={(): void => setNumberPeopleToDivision((numberPeopleToDivision || 0) + 1)}
              handleSubtractItem={(): void =>
                setNumberPeopleToDivision(
                  numberPeopleToDivision === 1 ? numberPeopleToDivision : (numberPeopleToDivision || 0) - 1
                )
              }
            />

            {mode === SelectedPaymentModeEnum.accountDivision && renderPaymentSummary}
          </>
        )}

        <Button type="submit" isGhost={false} disabled={isConfirmButtonDisabled} onClick={confirmModal}>
          {getTranslation('general.confirm')} {convertToCurrency(prices.finalPrice)}
        </Button>
      </S.ModalContent>
    </Modal>
  );
};

export { PaymentModeModal };
