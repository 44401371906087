import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';

import Sockette from 'sockette';
import { PaymentCategoryEnum, SocketReadyStateEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { TimelineStepEnum } from '~/interfaces/enums';
import { IApplicationState } from '~/redux-tools/store';
import { websocket } from '~/services/orderStatusSocket';
import GoogleAnalytics, { gaEvents } from '~/utils/analytics';
import { cleanOrderTime } from '~/redux-tools/store/pixPayment/actions';

export type SocketteEvent = Event & {
  target: {
    readyState: number;
  };
};

export interface EventData {
  updated_at: Date;
  status: TimelineStepEnum;
}

export interface UseOrderSocketProps {
  isOnline: boolean;
  updatedTime?: Date;
  acceptedTime?: Date;
  isAccepted: boolean;
  isSocketOpen: boolean;
  resetSocket: () => void;
  isSocketLoaded: boolean;
  currentStatus?: TimelineStepEnum;
  finishedStatus: TimelineStepEnum[];
}

export default function useOrderSocket(): UseOrderSocketProps {
  const dispatch = useDispatch();

  const { settings } = useSelector((state: IApplicationState) => state.establishment);
  const { order: nupayOrder } = useSelector((state: IApplicationState) => state.nupay);
  const { order: pixOrder } = useSelector((state: IApplicationState) => state.pixPayment);
  const { orderID, orderUUID } = useSelector((state: IApplicationState) => state.orderStatus.order);

  const isNupay = nupayOrder?.order?.payment_type?.category === PaymentCategoryEnum.nupay;
  const isIntegratedPix = pixOrder?.order?.payment_type?.category === PaymentCategoryEnum.integratedPix;

  const [isAccepted, setIsAccepted] = useState<boolean>(false);
  const [isSocketOpen, setIsSocketOpen] = useState<boolean>(false);
  const [updatedTime, setUpdatedTime] = useState<Date | undefined>();
  const [acceptedTime, setAcceptedTime] = useState<Date | undefined>();
  const [isSocketLoaded, setIsSocketLoaded] = useState<boolean>(false);
  const [isSocketResetting, setIsSocketResetting] = useState<boolean>(false);
  const [finishedStatus, setFinishedStatus] = useState<TimelineStepEnum[]>([]);
  const [wsConnection, setWsConnection] = useState<Sockette | undefined>(undefined);
  const [currentStatus, setCurrentStatus] = useState<TimelineStepEnum>(
    isIntegratedPix || isNupay ? TimelineStepEnum.waiting_payment : TimelineStepEnum.waiting
  );

  const [isOnline, setIsOnline] = useState(typeof navigator !== 'undefined' && navigator.onLine);

  const handleOpenConnection: (event: SocketteEvent) => any = useCallback((event) => {
    const isOpen = event?.target?.readyState === SocketReadyStateEnum.OPEN;

    setIsSocketOpen(isOpen);
    setIsSocketResetting(false);
    setIsSocketLoaded(true);
  }, []);

  const handleEvent = useCallback((event: MessageEvent, info: { id: number; name: string }) => {
    const steps = Object.values(TimelineStepEnum);

    const finishedStepIndex = steps.length - 2;

    const { status, updated_at, accepted_at } = typeof event?.data === 'string' ? JSON.parse(event?.data) : event.data;

    if (accepted_at) {
      setAcceptedTime(accepted_at);
    }

    if (updated_at) {
      setUpdatedTime(updated_at);
    }

    const newIndex = steps.findIndex((step) => step === status);

    if (newIndex >= 1) {
      setIsAccepted(true);
    }

    setCurrentStatus(status);

    GoogleAnalytics.trackEvent(gaEvents.orderStatusTimeline, {
      status,
      establishment_id: info.id,
      establishment_name: info.name
    });

    const finished = steps.filter((_, index) => {
      if (newIndex === finishedStepIndex) {
        return index <= newIndex;
      }

      return index < newIndex;
    });

    setFinishedStatus(finished);
  }, []);

  const handleCloseConnection = useCallback(() => {
    setIsSocketOpen(false);
  }, []);

  const checkOrderStatus = useCallback(() => {
    let socketConnection = wsConnection;
    let isSocketActive = isSocketOpen;

    setWsConnection((currentConnection) => {
      socketConnection = currentConnection;
      return currentConnection;
    });

    setIsSocketOpen((currentSocketStatus) => {
      isSocketActive = currentSocketStatus;
      return currentSocketStatus;
    });

    if (!socketConnection || !isSocketActive) {
      return;
    }

    return socketConnection.json({
      'order-id': orderID,
      'order-uuid': orderUUID,
      action: 'getorderstatus'
    });
  }, [isSocketOpen, orderID, orderUUID, wsConnection]);

  const resetSocket = useCallback(() => {
    if (wsConnection) {
      wsConnection.close();
    }

    setIsSocketOpen(false);
    setWsConnection(undefined);

    setIsSocketResetting(true);
  }, [wsConnection]);

  useEffect(() => {
    const onlineListener = () => setIsOnline(true);
    const offlineListener = () => setIsOnline(false);

    const visibilityListener = () => {
      if (document.visibilityState === 'visible') {
        if ((wsConnection || isSocketOpen) && !isSocketResetting) {
          resetSocket();
        }
      }
    };

    window.addEventListener('online', onlineListener);
    window.addEventListener('offline', offlineListener);

    document.addEventListener('visibilitychange', visibilityListener);

    return () => {
      window.removeEventListener('online', onlineListener);
      window.removeEventListener('offline', offlineListener);

      document.removeEventListener('visibilitychange', visibilityListener);
    };
  }, [checkOrderStatus, isOnline, isSocketOpen, isSocketResetting, resetSocket, wsConnection]);

  useEffect(() => {
    if (isSocketOpen && !!wsConnection && (orderID || orderUUID)) {
      setTimeout(() => checkOrderStatus(), 1500);
    }

    if (!isOnline && isSocketOpen && !!wsConnection) {
      wsConnection.close();

      setWsConnection(undefined);
    }

    return () => {
      if (isSocketOpen && !!wsConnection) {
        wsConnection.close();

        setWsConnection(undefined);
      }
    };
  }, [checkOrderStatus, isOnline, isSocketOpen, orderID, orderUUID, wsConnection]);

  useEffect(() => {
    if (!settings) return;

    const info = {
      id: settings?.id || 0,
      name: settings?.name || ''
    };

    const orderSocket = () => {
      return websocket({
        handleOpenConnection,
        handleCloseConnection,
        orderID: orderID || '',
        orderUUID: orderUUID || '',
        handleEvent: (event: MessageEvent) => handleEvent(event, info)
      });
    };

    if (!isSocketOpen && isOnline) {
      setWsConnection(orderSocket());
    }
  }, [handleCloseConnection, handleEvent, handleOpenConnection, isOnline, isSocketOpen, orderID, orderUUID, settings]);

  useEffect(() => {
    if (
      !!currentStatus &&
      currentStatus !== TimelineStepEnum.waiting_payment &&
      currentStatus !== TimelineStepEnum.waiting
    ) {
      dispatch(cleanOrderTime());
    }
  }, [currentStatus, dispatch]);

  return {
    isOnline,
    isAccepted,
    resetSocket,
    updatedTime,
    acceptedTime,
    isSocketOpen,
    currentStatus,
    isSocketLoaded,
    finishedStatus
  };
}
