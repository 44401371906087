import { action } from 'typesafe-actions';

import {
  Address,
  CartProduct,
  IDeliveryFeeStatic,
  IDeliveryFeeDynamic,
  HandlePageReloadProps,
  IDeliveryFeeNeighborhood
} from '~/interfaces/general';

import { CartActionTypes as actions } from './types';

interface ChangeProductQuantityProps {
  comboId?: string;
  quantity: number;
  productId?: string;
}

export const addProductToCart = (product: CartProduct) => action(actions.ADD_PRODUCT, product);

export const editProductToCart = (product: CartProduct) => action(actions.EDIT_PRODUCT, product);

export const removeProductToCart = (product: CartProduct) => action(actions.REMOVE_PRODUCT, product);

export const removeComboToCart = (comboId: string) => action(actions.REMOVE_COMBO, comboId);

export const cleanCart = () => action(actions.CLEAN_CART);

export const setEstablishmentOnCart = (slug: string) => action(actions.SET_ESTABLISHMENT, slug);

export const setUserAddressOnCart = (address: Address) => action(actions.SET_USER_ADDRESS, address);

export const removeUserAddressOnCart = () => action(actions.REMOVE_USER_ADDRESS);

export const setDeliveryFee = (deliveryFee: IDeliveryFeeDynamic | IDeliveryFeeNeighborhood | IDeliveryFeeStatic) =>
  action(actions.SET_DELIVERY_FEE, deliveryFee);

export const removeDeliveryFee = () => action(actions.REMOVE_DELIVERY_FEE);

export const setHasNewProductOnBag = (hasNewProductOnBag: boolean) =>
  action(actions.SET_NEW_PRODUCT_ON_BAG, hasNewProductOnBag);

export const setCartValues = (values: Record<string, number>) => action(actions.SET_CART_VALUES, values);

export const setHandlePageReload = (values: HandlePageReloadProps) => action(actions.SET_PREVENT_PAGE_RELOAD, values);

export const setUserAddressLatLngOnCart = (payload: { latitude: number; longitude: number }) =>
  action(actions.SET_ADDRESS_LAT_LNG, payload);

export const changeProductQuantity = ({
  productId,
  quantity,
  comboId
}: ChangeProductQuantityProps): {
  type: string;
  payload: { productId?: string; quantity: number; comboId?: string };
} => action(actions.CHANGE_PRODUCT_QUANTITY, { productId, quantity, comboId });
