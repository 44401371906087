/* eslint-disable @typescript-eslint/naming-convention */
export default {
  translations: {
    languagesPage: {
      title: 'Idiomas'
    },
    benefits: {
      yourBenefits: 'Suas vantagens'
    },
    flashMessage: {
      emptyBag: 'Sua sacola está vazia.'
    },
    suggestion: {
      advantageAndOrderToo: 'Aproveite e peça também'
    },
    about: {
      aboutStore: 'Sobre a loja',
      openTwentyFour: 'Aberto 24 horas'
    },
    errors: {
      orderError04: 'Algo deu errado ao enviar o pedido tente novamente! (Erro #04)'
    },
    languages: {
      english: 'Inglês',
      spanish: 'Espanhol',
      portuguese: 'Português'
    },
    option: {
      biggestValue: 'maior valor',
      mediumValue: 'média dos valores',
      priceIsCalculatedBy: 'O preço é calculado pela opção de '
    },
    offline: {
      youAreNotConnected: 'Parece que você está sem conexão',
      checkYourConnection: 'Verifique sua internet ou modo avião e tente novamente'
    },
    detail: {
      productDetail: 'Detalhe do produto',
      suggestionForYou: 'Sugestão para você',
      underEighteen: 'Produto não destinado a menores de 18 anos'
    },
    product: {
      card: 'Card do produto',
      startingAt: 'A partir de',
      currentlyUnavailable: 'O produto selecionado não está disponível para venda no momento.'
    },
    shortWeekdays: {
      sunday: 'dom',
      monday: 'seg',
      friday: 'sex',
      tuesday: 'ter',
      saturday: 'sab',
      thursday: 'qui',
      wednesday: 'qua'
    },
    alert: {
      error: 'Ícone de erro',
      default: 'Ícone padrão',
      warning: 'Ícone de aviso',
      success: 'Ícone de sucesso',
      info: 'Ícone de informação'
    },
    fullAge: {
      over18: 'Maior de 18?',
      declare: 'Declaro ter mais que 18 anos',
      prohibitedForMinors: 'Este pedido contém itens proibidos para menores de idade'
    },
    profilePage: {
      title: 'Seus pedidos com mais agilidade e segurança',
      description: 'Faça login e mantenha suas informações salvas para este e os próximos pedidos.'
    },
    weekdays: {
      friday: 'Sexta',
      tuesday: 'Terça',
      monday: 'Segunda',
      sunday: 'Domingo',
      thursday: 'Quinta',
      saturday: 'Sábado',
      wednesday: 'Quarta'
    },
    authPage: {
      title: 'Entre ou cadastre-se',
      description:
        'O cadastro facilita seus pedidos e pode ser usado em qualquer restaurante com Delivery ou QR Code pela Goomer!'
    },
    maintenance: {
      weBackSoon: 'Voltaremos em breve!',
      inMaintenance: 'Estamos em manutenção',
      toImproveYourExperience: 'Para melhorar ainda mais sua experiência, estamos ajustando algumas coisas por aqui!'
    },
    map: {
      confirmLocation: 'Confirmar localização',
      confirmLocationOnMap: 'Confirme sua localização no mapa',
      errorToGetAddressInfo: 'Ocorreu um erro ao buscar informações do seu endereço. Por favor, tente novamente.'
    },
    paymentPromoModal: {
      news: 'Novidade!',
      payBillFaster: 'Pague a conta com mais agilidade',
      everyoneAtTheTable:
        'Você e todos que estão a mesa poderão pagar a conta ao mesmo tempo em seus celurares. É rápido e fácil.'
    },
    appearancePage: {
      title: 'Aparência',
      lightTheme: 'Tema claro',
      darkTheme: 'Tema escuro',
      deviceConfig: 'Configuração do dispositivo',
      deviceConfigDescription: 'O aplicativo usará as mesmas configurações do seu celular.'
    },
    fee: {
      fee: 'Taxa',
      minute: 'min',
      free: 'Grátis',
      toBeAgreed: 'A combinar',
      localPickUp: 'Retirada no local',
      subjectToAvailability: 'Sujeito a disponibilidade',
      subjectToAvailabilityAndFees: '$t(fee.subjectToAvailability) e taxas'
    },
    waiter: {
      tryAgain: 'Tente novamente',
      callToWaiter: 'Chamar garçom',
      waiterCalled: 'Um garçom foi chamado',
      whatYouNeed: 'Informe o que você precisa',
      backToYouInTime: 'Ele lhe atenderá em instantes',
      errorToCallWaiter: 'Ocorreu um erro ao chamar o garçom'
    },
    optionals: {
      chooseMaxOption: 'Escolha {{max}} opção',
      chooseMaxOptions: 'Escolha {{max}} opções',
      chooseUptoMaxOption: 'Escolha até {{max}} opção',
      chooseUptoMaxOptions: 'Escolha até {{max}} opções',
      chooseBetweenMinAndMax: 'Escolha de {{min}} até {{max}} opções'
    },
    terms: {
      termsAccept: 'Li e aceito!',
      termsTitle: 'Termos e Política de Uso',
      termsAction: 'prosseguir com o cadastro',
      goomerAndRestaurant: 'da Goomer e do restaurante.',
      termsAgreement: 'Ao {{action}}, você concorda com os',
      serviceAndUsageTerms: 'Termos de Serviço e Política de Uso de Dados'
    },
    user: {
      change: 'Trocar usuário',
      phoneNumber: 'Número do seu celular',
      contactInfo: 'Informações de contato',
      inputNamePlaceholder: 'Como vamos te chamar',
      phoneInfo:
        'O número do celular será utilizado para te atualizar sobre o status do seu pedido, além de te identificar para agilizar os próximos pedidos.'
    },
    label: {
      storeImage: 'Imagem da loja',
      addSignal: 'Sinal de adição',
      imageOf: 'Imagem do {{name}}',
      subtractSignal: 'Sinal de subtração',
      radioHour: 'Escolher opção {{label}}',
      productImage: 'Imagem do produto {{name}}',
      highlightAction: 'Ação do item de destaque',
      securityCodeModalTitle: 'Título código de segurança'
    },
    securityCardCodeModal: {
      securityCode: 'Código de segurança',
      confirmAndSend: 'Confirmar e enviar',
      insertSecurityCode: 'Digite o código de segurança (CVV) de 3 ou 4 digitos escrito no verso do cartão',
      insertCodeAgain:
        'Por questões de segurança, pedimos que digite novamente o código de segurança localizado no verso do seu cartão.'
    },
    floatingFooter: {
      currentlyClosed: 'Fechado no momento!',
      unavailableProduct: 'Produto indisponível!',
      notAcceptingNewOrders: 'No momento, não estamos aceitando novos pedidos.',
      quantityLimitOnProduct: 'Você atingiu o número máximo de itens por pedido',
      unavailableDueToTheTime: 'No momento, o produto não está disponível devido ao dia/horário.'
    },
    errorPage: {
      ohNo: 'AHHH NÃO!!',
      wrongLink: 'O link está errado',
      pageNotFound: 'Não encontramos a página',
      whatHappened: 'O que pode ter ocorrido?',
      errorToLoadPage: 'Houve um erro ao carregar a página',
      noProducts: 'Nenhum cardápio com produtos cadastrados',
      deliveryDisabled: 'A Solução para Delivery está desabilitada no restaurante'
    },
    navigation: {
      more: 'Mais',
      bill: 'Conta',
      search: 'Busca',
      order: 'Pedido',
      menu: 'Cardápio',
      signIn: 'Entrar',
      waiter: 'Garçom',
      profile: 'Perfil',
      goToWhatsapp: 'Ir para o WhatsApp',
      returnToMenu: 'Voltar para o cardápio',
      goToNubankApp: 'Ir para o app do Nubank',
      returnToMyTab: 'Voltar para a minha conta'
    },
    cardEntry: {
      protectedArea: 'Área protegida',
      newCard: 'Novo cartão de crédito',
      debit: '$t(payment.debit, lowercase)',
      credit: '$t(payment.credit, lowercase)',
      safePayment: '$t(general.payment) seguro com:',
      editCard: 'Editar cartão de {{formattedCardType}}',
      invalidData: 'Dados inválidos. Verifique os dados informados e tente novamente.'
    },
    splash: {
      seeMenu: 'Ver cardápio',
      seeProducts: 'Ver produtos',
      placeAnOrder: 'Fazer pedido',
      accessMenu: 'Acessar o cardápio',
      orderByDelivery: 'Pedir por delivery',
      logInOrRegister: 'Entrar ou cadastrar',
      orderByTakeaway: 'Pedir para retirada',
      orderInRestaurant: 'Pedir no restaurante',
      loginTitle: 'Faça login e peça com mais agilidade'
    },
    pwaModal: {
      addInHomeScreen: 'Adicionar a tela inicial',
      bannerTitle: 'Tenha nosso app na palma da mão.',
      addOurApp: 'Adicione nosso app no seu celular.',
      bannerMessage: 'Clique aqui e salve na sua tela inicial.',
      noDownloadNeeded:
        '<strong>Não precisa baixar nada, </strong> basta adicionar nosso restaurante na tela inicial do seu celular e pedir com mais agilidade na próxima vez.'
    },
    search: {
      recentSearches: 'Buscas recentes',
      searchProducts: 'Buscar produtos',
      searchInMenu: 'Buscar no cardápio',
      seeAllProducts: 'Ver todos os produtos',
      productNotFound: 'Produto não encontrado',
      anyResults: 'Não encontramos nenhum resultado na busca por "{{term}}"',
      productsAndCategoriesFound: 'Encontramos {{productsQuantity}} produto(s) e {{categoriesQuantity}} categoria(s).'
    },
    paymentReceiptModal: {
      voucher: 'Comprovante',
      reviewData: 'Revisar dados',
      backToMyAccount: 'Voltar para minha conta',
      availableInYourAccount: ' Também estará disponível ao voltar para sua conta. ',
      mainReasons: 'Veja os principais motivos que podem ter gerado o erro no seu pagamento',
      paymentReceipt:
        'Este é o comprovante do seu pagamento.{{text}}Caso necessário apresente-o a um atendente na saída.'
    },
    ratingPage: {
      title: 'Avalie',
      sendRating: 'Enviar avaliação',
      subtitle: 'Sua opinião é muito importante!',
      successMessage: 'Avaliação enviada com sucesso',
      iconAriaLabel: 'Ícone da avaliação de {{item}}',
      errorMessage: 'Ocorreu um erro ao enviar a sua avaliação',
      successDescription: 'Sua resposta é muito importante para nós',
      errorDescription: 'Verifique as informações inseridas e tente novamente'
    },
    possibleStatus: {
      makeOrder: 'Fazer pedido',
      chooseName: 'Escolha o nome',
      reReadQrCode: 'Releia o QR Code',
      tableNumber: 'Informe o número da mesa',
      insertYourDocument: 'Insira o seu CPF/CNPJ',
      contactNumber: 'Informe o número para contato',
      deliveryAddress: 'Escolher o endereço de entrega',
      choosePaymentMethod: 'Escolher forma de pagamento',
      chooseScheduleDate: 'Escolher data de agendamento'
    },
    callToAction: {
      logInAndSaveYourData: 'Entre e tenha seus dados salvos para a próxima compra!',
      logInToLoyaltyProgram: 'Entre para participar do <strong>Programa de Fidelidade!</strong>',
      logInAndGetFreeDelivery:
        'Entre e aproveite a <strong>entrega grátis para pedidos acima de {{minimumValue}}!</strong>',
      logInAndGetFirstOrderDiscount:
        'Entre e receba <strong>{{percentageDiscount}} de desconto</strong> no primeiro pedido da loja!'
    },
    clubPage: {
      informData: 'informar meus dados',
      createSubscription: 'Criar Cadastro',
      subscribeToClub: 'Cadastre-se no Clube de Vantagens',
      subscriptionSuccess: 'Cadastro realizado com sucesso!',
      fillForm: 'Preencha os campos abaixo para realizar seu cadastro e utilizar o Clube no tablet.',
      clubRegisterError:
        'Houve um erro ao registrar-se no Clube de Vantagens. Tente iniciar o fluxo novamente no tablet ou fale com um atendente.'
    },
    receiptInfo: {
      total: 'Total da',
      totalPaid: 'Total pago',
      accountSummary: 'Resumo da conta',
      progressBar: 'Barra de progresso',
      paymentMadeOn: 'Pagamento efetuado em:',
      paymentNotMade: 'Pagamento não realizado',
      successPayment: 'Pagamento realizado com sucesso!',
      paymentErrorAnimation: 'Animação de erro no pagamento',
      paymentStatusAnimation: 'Animação de status do pagamento',
      paymentSuccessAnimation: 'Animação de sucesso no pagamento'
    },
    qrCodeScanner: {
      notAllowed: 'Permissão recusada',
      trackStart: 'Dispositivo ocupado',
      notReadable: 'Dispositivo ocupado',
      notFound: 'Nenhum dispositivo encontrado',
      devicesNotFound: 'Nenhum dispositivo encontrado',
      errorAccessingCamera: 'Erro ao acessar a camera',
      cameraError: 'Ocorreu um erro com a camera. Tente novamente.',
      allowAccessToCamera: 'Você precisa permitir o acesso à camera para prosseguir',
      aimYourPhoneCamera: 'Aponte a câmera do seu celular <br /> para o <strong>QR Code da {{qrCodeMode}}</strong>'
    },
    login: {
      continueWithEmail: 'Continuar com e-mail',
      continueWithGoogle: 'Continuar com Google',
      loginSuccess: 'Login realizado com sucesso',
      continueWithoutLogin: 'Continuar sem logar',
      registrationCompleted: 'Cadastro concluído!',
      select: 'Selecione uma opção para continuar',
      googleError: 'Ocorreu um erro ao realizar o login via Google. Tente novamente.',
      info: 'Você pode usar seu login em qualquer restaurante que utilize Delivery ou QR Code da Goomer. Aproveite!'
    },
    morePage: {
      about: {
        title: 'Sobre nós',
        description: 'Conheça a história do nosso restaurante'
      },
      languages: {
        title: '$t(languagesPage.title)',
        description: 'Escolha o idioma que preferir'
      },
      appearance: {
        title: '$t(appearancePage.title)',
        description: 'Escolha entre escuro, claro ou automático'
      },
      rating: {
        title: '$t(ratingPage.title) sua experiência',
        description: 'Deixe uma avaliação sobre sua experiência conosco'
      }
    },
    howItWorks: {
      mediumValue: 'Valor médio',
      biggestValue: 'Maior valor',
      biggestValueDescription:
        'O preço final vai considerar o <strong>valor mais alto</strong> entre as opções que você escolher.',
      mediumValueDescription:
        'O preço final vai considerar a <strong>média dos valores</strong> entre as opções que você escolher.',
      howItWorksExample:
        'Exemplo: Se você escolher a opção A (R$ {{firstValue}}) e a opção B (R$ {{secondValue}}), <strong>o preço final será de R$ {{finalValue}}.</strong>'
    },
    scheduling: {
      to: 'as',
      scheduling: 'Agendamento',
      remove: 'Excluir agendamento?',
      confirmTime: 'Confirmar horário',
      scheduleDelivery: 'Agendar entrega',
      scheduleOrder: 'Agendamento de pedidos',
      scheduleLocal: 'Agendar retirada no local',
      approximateTime: 'Os horários de entrega são aproximados',
      closedMessage: 'Estamos fechados no momento, mas você ainda pode agendar seu pedido!',
      nowYouCanScheduleYourOrder:
        'Agora seu pedido pode ser agendado pelo aplicativo. Receba em casa ou retire no dia e horário escolhido por você.'
    },
    couponMessage: {
      useInOnsiteOrder: 'usar em pedidos no balcão',
      couponWasExpired: 'O cupom {{infoCode}} está expirado',
      invalidCouponWeekday: 'Cupom inválido para este dia da semana',
      couponOnlyValidFor: 'Cupom válido somente para {{formattedMessage}}',
      couponDisabledByStore: 'Este cupom foi desabilitado pelo estabelecimento!',
      couponNotFound: 'Cupom não encontrado. Confira o código e digite novamente.',
      couponValidBetween: 'Cupom válido somente entre {{initDate}} e {{finishDate}}',
      couponNeedOrderValueOver: 'Para ativar o cupom, você precisa ter um pedido acima de {{orderValue}}'
    },
    discount: {
      applied: 'Aplicado',
      applyCoupon: 'Aplicar cupom',
      couponCode: 'Código do cupom',
      insertCoupon: 'Inserir cupom',
      deleteCoupon: 'Excluir cupom?',
      couponApplied: 'Cupom aplicado',
      applyingCoupon: 'Aplicando cupom',
      discountCoupon: 'Cupom de desconto',
      loyaltyDiscount: 'Desconto fidelidade',
      couponAriaLabel: 'Área para inserir cupom',
      discountApplied: '{{value}} de desconto{{text}}!',
      percentApplied: '{{value}}% de desconto{{text}}!',
      enterCouponDescription: 'Digite o código do cupom abaixo para validar seu desconto',
      discountMaxValue: 'Este desconto está sendo aplicado até o valor máximo de {{value}} do total'
    },
    menu: {
      ofDiscount: 'de desconto ',
      hasMinimumValue: 'para pedidos acima de {{minValue}}',
      validInLoyaltyProgram: 'Pedido válido no Programa de Fidelidade!',
      firstOrderInStore: 'Primeiro pedido na loja? <strong>{{errorMessage}}</strong>',
      missingValueInLoyaltyProgram: 'Faltam <strong>{{value}}</strong> para participar do Fidelidade',
      youHasDiscount: '<strong>{{name}} • </strong> Você tem <strong>{{discountText}} de desconto </strong>',
      youHasDiscountInFirstOrder: ' Você tem <strong>{{couponValue}}</strong> para usar no seu primeiro pedido!',
      youEarnedDiscount:
        '<strong>Fidelidade:</strong> você ganhou {{discountValue}} de desconto para usar no seu pedido!'
    },
    firstOrder: {
      registerNow: 'Cadastre-se agora',
      storeFirstOrder: 'Primeiro pedido na loja?',
      firstOrderDiscount: 'Desconto Primeiro Pedido',
      firstOrderRules: 'Abrir regras do desconto de primeiro pedido',
      validForOrders: 'Válido para pedidos de no mínimo {{minimumValue}}.',
      registerToGetDiscount: 'Cadastre-se e receba {{discountPercentage}} de desconto!',
      discountInFinalOrder: 'O desconto será aplicado automaticamente ao finalizar o pedido.',
      getFirstOrderDiscount: 'Ganhe {{discountPercentage}} de desconto no seu primeiro pedido em ',
      shouldBeLoggedIn: 'Para utilizar o desconto de primeiro pedido, o usuário deve estar logado.',
      firstOrderDiscountNotCumulative: 'O desconto de primeiro pedido não é cumulativo com outros descontos ou cupons.'
    },
    localOrder: {
      myOrder: 'Meu pedido',
      ofClient: 'do cliente',
      lastName: 'e sobrenome',
      tableNumber: 'Número da mesa',
      makingOrder: 'Fazendo pedido',
      whichIsYourTable: 'Qual sua mesa?',
      howToCallYou: 'Como vamos te chamar',
      howYouWillPay: 'Como você vai pagar',
      localOrderTitle: 'Nome {{clientName}}',
      orderOf: 'Pedido da {{modeAndNumber}}',
      enterClientName: 'Informe o nome do cliente',
      ifWeNeedTalkToYou: 'caso a gente precise falar com você',
      orderWithYourCurrentType: 'Deseja fazer o pedido na {{orderType}} atual?',
      waiterToLocateYourTable: 'Esta informação é útil para o garçom localizar sua mesa na entrega',
      errorToGetCartData: 'Houve um erro ao buscar os dados do seu carrinho. Leia o QR Code e tente novamente.'
    },
    loggedArea: {
      cards: 'Meus cartões',
      default: 'Área logada',
      account: 'Conta e acesso',
      personalData: 'Meu perfil',
      addresses: 'Meus endereços',
      back: 'Voltar para a área logada',
      notifications: 'Notificações por e-mail',
      errorMessage: 'Erro ao salvar. Tente novamente!',
      cardsDescription: 'Edite ou atualize seus cartões',
      successMessage: 'Informações alteradas com sucesso!',
      personalDataDescription: 'Edite ou atualize seus dados',
      addressesDescription: 'Edite ou atualize seus endereços',
      notificationsDescription: 'Receba notificacões direto no e-mail',
      message: 'Seu cadastro é válido em qualquer restaurante com Delivery ou QR Code da Goomer.',
      notificationsExplanation:
        'Desejo receber e-mails dos restaurantes onde já fiz pedidos com ofertas, novidades e outra comunicações.'
    },
    feedback: {
      newOrder: 'Novo Pedido',
      seeMyOrder: 'Ver meu pedido',
      seeMyOrders: 'Ver meus pedidos',
      unavailableMenu: 'Cardápio indisponível',
      soonInYourTable: 'Em breve estará em sua mesa',
      orderCompleted: 'Pedido finalizado com sucesso!',
      qrcodeInactive: 'Upss! O QR Code não está ativo',
      orderToKitchenSent: 'Pedido enviado para a cozinha!',
      callAnAttendant: 'Chame um atendente para te orientar',
      contactEstablishment: 'Entre em contato com o estabelecimento',
      newOrderInOrderMode: 'Novo pedido na {{orderMode}} {{number}}',
      sentOrderToKitchen: 'Pedido enviado para a cozinha com sucesso!',
      orderNotSent: 'Upss! Ocorreu um problema e o pedido não foi enviado',
      checkYourConnection: 'Verifique sua conexão e caso o problema continue chame um atendente.',
      toSeeOrderDetails: 'Para ver os detalhes do pedido acesse o seu Gestor de Pedidos no painel Goomer',
      notPaidForYourOrder:
        'Caso não tenha realizado o pagamento de seu pedido, dirija-se ao caixa e aguarde seu pedido.'
    },
    information: {
      local: 'Local',
      tapOn: 'Toque em ',
      active: 'Ative a ',
      safariSetting: 'aA',
      settings: 'Configurações',
      localization: 'Localização',
      turnOnThe: 'Ative a parte de ',
      websiteAdjusts: 'Ajustes de site',
      websiteConfiguration: 'Configurações do site',
      localizationDisabled: 'Localização desativada',
      nextToTheWebsite: ' ao lado do endereço do site no Safari',
      makeAnOrder: 'Agora é só realizar seus pedidos. Aproveite!',
      openSmartphoneCamera: 'Abra a câmera do seu celular ou app de QR Code.',
      scanQrcode: 'Escaneie o código que está na mesa ou no balcão do restaurante.',
      activateLocalization: 'Ative a localização nas configurações do seu navegador!',
      tapOnThreeDots: 'À direita da barra do link toque no ícone dos três pontos ao lado',
      canOrderWithGoomer: 'Agora você pode pedir com a Goomer diretamente no restaurante.',
      stepsBelowToActivate:
        'Siga os passos abaixo para ativá-la. Precisamos dela para encontrar o restaurante mais próximo a você.'
    },
    accountVerification: {
      toEmail: 'ao e-mail',
      checkCode: 'Código {{check}}',
      resendCode: 'Reenviar código',
      almostThere: 'Estamos quase lá!',
      resendNewCode: 'Reenviar código em:',
      logInOrRegister: 'Entre ou cadastre-se',
      invalidCode: 'inválido. Tente novamente!',
      insertSentCode: 'Insira o código que foi enviado para',
      insertYourEmail: 'Insira seu e-mail abaixo para continuar',
      otherRegister: 'a outro $t(general.subscription, lowercase)',
      completeYourSignUp: 'Complete seu $t(general.subscription, lowercase)',
      sendCodeErrorMessage: 'Ocorreu um erro ao enviar o código por e-mail. Tente novamente.',
      googleLoginErrorMessage: 'Ocorreu um erro ao realizar o login via Google. Tente novamente.',
      signUpInformation: 'Estes dados nos ajudam a adaptar o aplicativo às suas necessidades e preferências.',
      customerPhoneNumberInfo: 'O número do celular será utilizado para te atualizar sobre o status do seu pedido.',
      phoneAlreadyRegistered:
        'Este telefone já está vinculado {{validation}}. Por favor, digite outro número ou volte e faça o login.'
    },
    loyalty: {
      hour: 'hora',
      hours: 'horas',
      completeUntil: 'Complete até',
      loyaltyProgram: 'Programa de Fidelidade',
      andParticipateOf: '$t(authPage.title) e participe do',
      loyaltyProgramConcluded: 'Programa de Fidelidade concluído!',
      validOrdersBy: 'São válidos pedidos feitos por {{channelNames}}.',
      orderAndEarn: 'Peça {{programOrders}} vezes e ganhe {{discountValue}}!',
      discountEarned: 'Você ganhou um desconto de {{discountValue}}, aproveite!',
      useLoyaltyDiscount: 'Para utilizar o desconto Fidelidade, o usuário deve estar logado.',
      onlyValidOrders: 'Apenas pedidos válidos e finalizados são contabilizados no Programa.',
      scoreWillBeConfirmedAfterBill: 'A pontuação será confirmada após o fechamento da conta.',
      orderWillBeCountedInLoyaltyProgram: 'Seu pedido será contabilizado no Programa de Fidelidade.',
      scoreWillBeConfirmedAfterOrder: 'A pontuação será confirmada após o restaurante finalizar o pedido.',
      discountNotCumulative: 'O desconto do Programa de Fidelidade não é cumulativo com outros descontos ou cupons.',
      purchasesValidInProgram:
        'Para que as compras sejam válidas no Programa o usuário deve estar logado no momento do checkout.',
      missingOrderValueToLoyaltyProgram:
        'Faltam <strong>{{remainingValue}}</strong> para que seu pedido seja válido no Programa de Fidelidade.',
      autoDiscount:
        'O desconto será aplicado automaticamente na compra seguinte à conclusão do Programa e poderá ser usado em até {{days}} dias.',
      nextOrderCanBeMadeAt:
        '<strong>Fidelidade:</strong> seu próximo pedido válido para o Programa de Fidelidade poderá ser feito em {{countdown}}.',
      onlyValidOrdersInIntervalHours:
        'Pedidos válidos para o Programa de Fidelidade devem ser feitos com {{orderIntervalHours}} {{hours}} de intervalo.'
    },
    address: {
      state: 'UF',
      city: 'Cidade',
      zipcode: 'CEP',
      address: 'Endereço',
      delivery: 'Entrega',
      neighborhood: 'Bairro',
      complement: 'Complemento',
      noAddress: 'Sem endereço',
      saveAddress: 'Salvar endereço',
      feeToBeAgreed: 'Taxa a combinar',
      addAddress: 'Adicionar endereço',
      dontHaveZipcode: 'Não sei meu CEP',
      complementPlaceholder: 'Casa, apto',
      confirmAddress: 'Confirmar endereço',
      approximateDelivery: 'Entrega aprox.',
      referencePoint: 'Ponto de referência',
      cityNotFound: 'Cidade não encontrada',
      whatYourAddress: 'Qual seu endereço?',
      deliveryAddress: 'Endereço de entrega',
      selectAnAddress: 'Selecione um endereço',
      addNewAddress: 'Adicionar novo endereço',
      confirmingAddress: 'Confirmando endereço',
      addressNotFound: 'Endereço não encontrado',
      cityPlaceholder: 'Digite o nome da cidade',
      tooFarMessage: 'Puxa, você está muito longe!',
      neighborhoodNotFound: 'Nenhum bairro encontrado',
      streetNameLabel: 'Rua, avenida, praça, travessa',
      deliveryTimeAndTax: 'Ver tempo e taxa de entrega',
      fetchCityError: 'Erro ao buscar sugestões de cidade',
      zipcodeNotFound: '$t(address.zipcode) não encontrado',
      streetNameOrZipcode: 'Nome da rua ou $t(address.zipcode)',
      noAddressAdded: 'Nenhum endereço adicionado até o momento',
      tooFarDescription: 'Endereço fora da nossa área de entrega',
      selectAddressToDelivery: 'Selecione um endereço para entrega',
      selectAnAddressDescription: 'para ver tempo e taxa de entrega',
      enterStreetNameOrZipcode: 'Digite o $t(address.streetNameOrZipcode)',
      selectAnAddressToDelivery: '$t(address.selectAnAddress) para entrega',
      addAddressMessage: 'Adicione um endereço para ver o tempo e as taxas de entrega',
      addressNotFoundContactStore: 'Endereço não localizado. Entre em contato com a Loja!',
      fetchAddressError: 'Estamos com dificuldade para encontrar seu $t(address.zipcode), digite os dados manualmente',
      editAndRemoveInstructions:
        'Deslize um endereço (direita para editar ou esquerda para excluir) ou adicione um novo endereço.'
    },
    myTab: {
      available: 'disponível',
      accountPaid: 'Conta paga',
      requestBill: 'Pedir conta',
      howToPay: 'Como vai pagar',
      missingToPay: 'Falta pagar',
      totalTable: 'Total da mesa',
      closedTable: 'Mesa fechada',
      enterValue: 'Digitar valor',
      amountToPay: 'Valor a pagar',
      totalOf: 'Total da {{mode}}',
      valueToPay: 'Pagar {{value}}',
      payMyBill: 'Pagar minha conta',
      availablePlural: 'disponíveis',
      accountSummary: 'Resumo da conta',
      requestedBill: 'Conta solicitada',
      billClosing: 'Fechamento da conta',
      subtotalOf: 'Subtotal da {{mode}}',
      pricePerPerson: 'Valor por pessoa:',
      leaveReview: 'Deixar uma avaliação',
      summaryValues: 'Resumo dos valores',
      payTotalTable: 'Pagar total da mesa',
      divideByPeaople: 'Dividir por pessoas',
      waitingForPayment: 'Aguardando pagamento',
      payRemainingValue: 'Pagar valor restante',
      dontLikeToLeaveReview: 'Não quero avaliar',
      paymentViaDashboard: 'Pagamento via painel',
      notOrderedYet: 'Você ainda não fez um pedido',
      noOrderSoFar: 'Nenhum pedido feito até o momento',
      bringUpBill: 'Um atendente já irá trazer sua conta',
      billWasPaid: 'A conta foi paga e sua lista está vazia.',
      checkboxServiceTax: 'Caixa de seleção para taxa de serviço',
      processingAccount: 'Estamos processando sua conta, aguarde',
      checkboxServiceCharge: 'Caixa de seleção para taxa de serviço',
      backToAccountTryAgain: 'Volte para a conta e tente novamente.',
      itemsWillAppearHere: 'Faça um pedido e seus itens aparecerão aqui',
      callAnAttendantToCloseBill: 'Para fechar a conta chame um atendente',
      anotherLookInMenu: 'Que tal dar mais uma olhadinha em nosso cardápio?',
      singleAmount: 'O valor avulso não pode ser maior que o valor restante.',
      howMuchYouWantToPay: 'Digite o quanto deseja pagar do total da conta dessa mesa',
      likeToLeaveReview: 'Gostaria de deixar uma avaliação sobre nosso estabelecimento?',
      reopenAccount: 'A conta total foi paga e fechada. Para reabri-lá, faça um novo pedido',
      makeNewPayment: 'Foi realizado um pagamento recente nesta mesa. Deseja fazer um novo pagamento?',
      usePixCodeInYourApp:
        'Copie o código abaixo e utilize o <strong>Pix Copia e Cola</strong> no seu aplicativo de pagamento. Aguarde até a <strong>exibição do comprovante</strong> confirmando seu pagamento.'
    },
    order: {
      and: 'e',
      between: 'entre',
      prepare: 'Preparo',
      myOrder: 'Meu pedido',
      viewOrder: 'Ver pedido',
      lastOrder: 'Último pedido',
      orderReady: 'Pedido pronto!',
      inPreparation: 'Em preparo!',
      orderSent: 'Pedido enviado!',
      sendOrder: 'Envio do pedido',
      cancelOrder: 'Cancelar pedido',
      orderExpired: 'Pedido expirado',
      orderPending: 'Pedido pendente',
      sendingOrder: 'Enviando pedido',
      confirmOrder: 'Confirmar pedido',
      willBeDelivered: 'será entregue:',
      orderDetail: 'Detalhes do pedido',
      orderCanceled: 'Pedido cancelado',
      sendYourOrder: 'enviar seu pedido',
      orderConfirmed: 'Pedido confirmado',
      addMoreItem: 'Adicionar mais itens',
      cancelingOrder: 'Cancelando pedido',
      tryAgain: 'Faça o pedido novamente',
      payingOrder: 'Realizando pagamento',
      paymentPending: 'Pagamento pendente',
      paymentReceived: 'Pagamento recebido',
      sendViaWhatsapp: 'Enviar via WhatsApp',
      scheduleReady: 'Tudo pronto! {{ready}}',
      mercadoPagoLink: 'Link do Mercado Pago',
      orderStatus: 'Acompanhamento de pedidos',
      payAndMakeOrder: 'Pagar e fazer o pedido',
      orderTracking: 'Acompanhamento de pedidos',
      orderPreparing: 'O pedido está em preparo',
      scheduleOrderTime: 'O pedido {{available}}',
      successIllustration: 'Ilustração de sucesso',
      receiveNotifications: 'Receber notificações',
      waitingPayment: 'Pedido aguardando pagamento',
      sorryForInconvenience: 'Desculpe o transtorno.',
      orderCanBeTaken: 'O pedido já pode ser retirado!',
      orderTotalValue: 'Valor total do pedido: {{value}}',
      cancelOrderQuestion: 'Deseja cancelar todo o pedido?',
      orderWentOutForDelivery: 'O pedido saiu para entrega!',
      scheduledDelivery: 'Sua entrega foi agendada com sucesso!',
      watchAnimation: 'Animação de um relógio passando as horas',
      scheduledTakeaway: 'Sua retirada foi agendada com sucesso!',
      waitingStoreConfirmation: 'Aguardando a confirmação da loja',
      restaurantCanceledOrder: 'O restaurante cancelou seu pedido.',
      willBeAvailableToTakeaway: 'estará disponível para retirada:',
      acceptedByStoreFeedback: 'Feedback de pedido aceito pela loja!',
      noOrderStatusAvailable: 'Nenhum pedido em andamento no momento',
      minimumValueNotMet: 'Valor informado inferior ao valor do pedido.',
      checkOrderDetails: 'Veja abaixo os detalhes do seu último pedido.',
      redirectingToSafeEnvironment: 'Redirecionando para ambiente seguro',
      problemSendingOrder: 'Problemas para enviar o pedido pelo WhatsApp?',
      cancelOrderWarning: 'Ao cancelar, todo o seu progresso será perdido.',
      expectedDelivery: 'Previsão de entrega <strong>{{waitingTime}}</strong>',
      expectedTakeaway: 'Previsão de retirada <strong>{{waitingTime}}</strong>',
      statusWillAppearHere: 'Faça um pedido e acompanhe o progresso dele por aqui',
      whatsappUpdatesMessage: 'Você receberá as atualizações do seu pedido pelo WhatsApp!',
      zeroedOrderMessage: 'O valor do pedido deve ser maior que o subtotal {{value}} para prosseguir.',
      valueSmallerThanLoyalty: 'O valor do subtotal do pedido {{value}} precisa ser maior que o desconto fidelidade.',
      cannotConfirmOrderInfo:
        'Não conseguimos confirmar os dados do seu pedido. Entre em contato com o restaurante para agilizar seu atendimento.'
    },
    errorMessage: {
      invalidCoupon: 'Cupom inválido.',
      expiredCoupon: 'O cupom usado expirou.',
      tabInfoError: 'Erro ao buscar informações da comanda.',
      invalidCouponHour: 'Cupom inválido para este horário.',
      invalidProductsInOrder: 'Produtos inválidos no pedido.',
      checkCardDetails: 'Verifique os dados do cartão informado.',
      checkTheCardNumber: 'Verifique o número do cartão informado',
      checkCvvCardCode: 'Verifique o código de segurança informado',
      orderRejectedByMercadoPago: 'Pedido rejeitado pelo Mercado Pago.',
      checkExpiryDate: 'Verifique a data de expiração do cartão informado',
      invalidCouponForThisOrder: 'Cupom inválido para o valor deste pedido',
      pleaseChoosePaymentMethod: 'Por favor, escolha um método de pagamento.',
      errorToSaveAddress: 'Erro ao salvar o endereço utilizado neste pedido.',
      errorToGetBill: 'Algo deu errado ao buscar sua conta. Tente novamente.',
      errorToCloseBill: 'Algo deu errado ao fechar sua conta. Tente novamente.',
      couponDisabledByStore: 'Este cupom foi desabilitado pelo estabelecimento.',
      storeIdNotFound: 'Não foi possível encontrar o ID da loja. Tente novamente.',
      amountOfChange: 'O valor do troco não pode ser menor que o valor total do pedido',
      errorToSendOrder: 'Algo deu errado ao enviar o pedido. Atualize a página por favor!',
      searchingDeliveryFee: 'Ocorreu um erro ao buscar a taxa de entrega. Tente novamente.',
      errorToFindLocation: 'Algo deu errado ao buscar localização, por favor tente novamente',
      storeNotAcceptingWithoutSchedule: 'Esta loja não está aceitando pedidos sem agendamento.',
      belowMinimumValue: 'O pedido está abaixo do valor mínimo para delivery deste restaurante.',
      errorGettingTableInfo: 'Algo deu errado ao buscar as informações da mesa. Tente novamente.',
      checkYourPhoneNumber: 'Verifique se o número do seu celular está correto e tente novamente.',
      minimumPixOrderValue: 'O pedido mínimo com Pix é R$ 20,00. Escolha outra forma de pagamento.',
      errorToMakePayment: 'Algo deu errado ao realizar o pagamento. Por favor, recarregue a página.',
      errorToGenerateMercadoPagoLink: 'Algo deu errado com ao gerar o link de pagamento com Mercado Pago',
      mercadoPagoMinimumValue: 'O valor das transações junto ao Mercado Pago devem ser no mínimo de R$ 0,50',
      errorToSendOrderCheckData: 'Algo deu errado ao enviar o pedido. Verifique os dados e tente novamente.',
      errorToCheckYourLogin: 'Algo deu errado ao verificar seu login via {{loginOptionMode}}. Tente novamente.',
      tableNumberNotFound: 'Uma mesa com este número não foi encontrada. Verifique o número e tente novamente.',
      notBeGenerated: 'Não foi possível gerar o link de pagamento. Por favor, escolha outra forma de pagamento.',
      checkCardDetailsContactStore: 'Verifique os dados do cartão informado, caso o problema persista contate-nos.',
      errorToGetOptionals: 'Algo deu errado ao buscar os opcionais do produto. Por favor, tente novamente. (Erro #10)',
      yourBillHasBeenRequested:
        'Não foi possível enviar o pedido pois sua conta já foi solicitada. Fale com um atendente.',
      emailConfirmationExceeded:
        'Número máximo de confirmação por e-mail excedido, tente novamente daqui alguns segundos.',
      browserDoesntSupportGeolocation:
        'Seu navegador não tem suporte a geolocalização, por favor tente novamente em outro navegador.',
      errorToGenerateLink:
        'Algo deu errado ao gerar o link do mercado pago, por favor tente novamente ou utilize outro método de pagamento',
      checkTheData:
        'Algo deu errado ao enviar o pedido, por favor verifique os dados e tente novamente. Se o erro persistir, contate a loja.',
      notAllowDirectPayment:
        'A entrega com taxa a combinar não permite o pagamento direto pelo App. Por favor, escolha outra forma de pagamento.',
      errorToSendOrderWithSchedule:
        'Algo deu errado ao enviar o pedido devido ao horário do agendamento, por favor selecione novamente o horário'
    },
    general: {
      or: 'ou',
      to: 'às',
      and: 'e',
      cpf: 'CPF',
      from: 'das',
      new: 'Novo',
      days: 'dias',
      name: 'Nome',
      paid: 'Pago',
      cnpj: 'CNPJ',
      key: 'Chave',
      exit: 'Sair',
      type: 'Tipo',
      today: 'Hoje',
      skip: 'Pular',
      help: 'Ajuda',
      table: 'Mesa',
      price: 'Preço',
      items: 'Itens',
      tab: 'Comanda',
      edit: 'Editar',
      free: 'Grátis',
      back: 'Voltar',
      total: 'Total',
      card: 'Cartão',
      copy: 'Copiar',
      open: 'Aberta',
      rules: 'Regras',
      greeting: 'Olá',
      order: 'Pedido',
      close: 'Fechar',
      email: 'E-mail',
      coupon: 'Cupom',
      google: 'Google',
      gotIt: 'Entendi',
      add: 'Adicionar',
      number: 'Número',
      signIn: 'Entrar',
      change: 'Trocar',
      closed: 'Fechada',
      balcony: 'Balcão',
      remove: 'Excluir',
      qrCode: 'QR Code',
      phone: 'Telefone',
      again: 'novamente',
      cancel: 'Cancelar',
      product: 'Produto',
      view: 'Visualizar',
      inOrder: ' na nota',
      sending: 'Enviando',
      invalid: 'Inválido',
      later: 'Mais tarde',
      signUp: 'Cadastrar',
      address: 'Endereço',
      daySuffix: '-feira',
      takeaway: 'Retirada',
      showAll: 'Ver todos',
      showMore: 'Ver mais',
      subtotal: 'Subtotal',
      discount: 'Desconto',
      confirm: 'Confirmar',
      payment: 'Pagamento',
      optional: 'Opcional',
      category: 'Categoria',
      loyalty: 'Fidelidade',
      removing: 'Excluindo',
      continue: 'Continuar',
      showLess: 'Ver menos',
      consumption: 'Consumo',
      typeHere: 'Digite aqui',
      receipt: 'Comprovantes',
      required: 'Obrigatório',
      editItem: 'Editar item',
      confirmed: 'Confirmado',
      subscription: 'Cadastro',
      validUntil: 'Válido até',
      categories: 'Categorias',
      okThanks: 'Ok, obrigado',
      myAccount: 'Minha conta',
      onTheBalcony: 'No balcão',
      scheduling: 'Agendamento',
      noThanks: 'Não, obrigado',
      everyDay: 'Todos os dias',
      copyCode: 'Copiar código',
      yesRemove: 'Sim, excluir',
      okayGotIt: 'Ok, entendi!',
      removeItem: 'Excluir item',
      yesCancel: 'Sim, cancelar',
      seeDetails: 'Ver detalhes',
      unavailable: 'Indisponível',
      okayLetsGo: 'Ok, vamos lá!',
      confirmation: 'Confirmação',
      reviewData: 'Revisar dados',
      observations: 'Observações',
      tryAgain: 'Tentar novamente',
      invalidDate: 'Data inválida',
      stillToBePaid: 'Falta pagar',
      startChat: 'Iniciar conversa',
      firstOrder: 'Primeiro pedido',
      serviceTax: 'Taxa de serviço',
      deliveryTax: 'Taxa de entrega',
      invalidField: 'Campo inválido',
      invalidValue: 'Valor inválido',
      anExperience: 'Uma experiência',
      howDoesItWork: 'Como funciona?',
      viewOnly: 'Apenas visualização',
      orderSummary: 'Resumo do pedido',
      totalWithTax: 'Total com a taxa',
      invalidEmail: 'E-mail inválido!',
      saveChanges: 'Salvar alterações',
      waitMoment: 'Aguarde um momento',
      chooseOption: 'Escolha uma opção',
      emailExample: 'nome@email.com.br',
      chooseOneOption: 'Escolha 1 opção',
      birthdayDate: 'Data de nascimento',
      houseSuggestion: 'Sugestão da casa',
      principalMenu: 'Cardápio principal',
      discountOfType: 'Desconto {{type}}',
      insertYourName: 'Digite o nome aqui',
      otherCategories: 'Outras categorias',
      backToMenu: 'Voltar para o cardápio',
      subtotalCoupon: 'Subtotal com cupom',
      reportAProblem: 'Informar um problema',
      loadingImage: 'Imagem de carregamento',
      addObservation: 'Adicionar observação',
      totalWithDiscount: 'Total com desconto',
      insertDocument: 'Digite o CPF/CNPJ aqui',
      workingHours: 'Horário de funcionamento',
      firstNameAndLastName: 'Nome e sobrenome',
      documentInOrder: '{{document}} na nota: ',
      subtotalDiscount: 'Subtotal com desconto',
      successfullyCopied: 'Copiado com sucesso!',
      enterAndParticipate: 'Entrar e participar',
      myOrder: 'Meu $t(general.order, lowercase)',
      itemAddedToCart: 'Item adicionado a sacola',
      contactStore: 'Entre em contato com a loja',
      discountForTakeaway: 'Desconto para retirar',
      paymentWays: 'Formas de $t(general.payment)',
      subtotalWithDiscount: 'Subtotal com desconto',
      bitFarAway: 'Parece que você está meio longe',
      paymentMethods: 'Formas de $t(general.payment)',
      optionsLimitReached: 'Limite de opções atingido!',
      earnDiscount: '{{discountPercentage}}% desconto!',
      paymentConfirmed: '$t(general.payment) confirmado',
      enterYourPhoneNumber: 'Informe o número do seu celular',
      invalidBirthdayDate: '$t(general.birthdayDate) inválida',
      orderDetails: 'Detalhes do $t(general.order, lowercase)',
      cancelPayment: 'Cancelar $t(general.payment, lowercase)',
      namePlaceholder: 'Digite seu $t(general.name, lowercase)',
      enterYourEmail: 'Informe seu $t(general.email, lowercase)',
      successConfigMessage: 'Configuração alterada com sucesso!',
      emailPlaceholder: 'Digite seu $t(general.email, lowercase)',
      enterValidPhoneNumber: 'Informe um número de celular válido',
      subtotalWithLoyaltyDiscount: 'Subtotal com desconto fidelidade',
      paymentConfirm: 'Confirmação do $t(general.payment, lowercase)',
      shouldInsertDocument: 'Você precisa informar seu {{- document}}',
      invalidDocument: '{{- document}} $t(general.invalid, lowercase)',
      paymentNotFinished: '$t(general.payment, lowercase) não realizado',
      changePaymentMethod: 'Alterar forma de $t(general.payment, lowercase)',
      enterYourNameAndLastName: 'Informe seu nome e sobrenome (sem abreviações)',
      observationPlaceholder: 'Digite as $t(general.observations, lowercase) aqui...',
      contactStoreForHelp: 'Entre em contato com a loja para resolver este problema.',
      toUseTheApp: 'Para utilizar o app é necessário que você esteja perto do estabelecimento',
      freeDeliveryMinimumValue: '<strong>Entrega grátis</strong> em pedidos acima de {{value}}!',
      whatsappSendReminder: 'Lembre-se de enviar seu pedido via WhatsApp para o restaurante. É fácil e rápido!',
      minimumValue: 'Pedido <strong>mínimo de {{value}}</strong> para delivery. Não inclusa a taxa de entrega.',
      observationMessage:
        'Converse diretamente com o estabelecimento caso queira modificar algum item. Neste campo não são aceitas modificações que podem gerar cobrança adicional.'
    },
    payment: {
      debit: 'Débito',
      credit: 'Crédito',
      money: 'Dinheiro',
      pixKey: 'Chave Pix',
      useCard: 'Usar cartão',
      byNubank: 'via Nubank',
      expiryDate: 'Validade',
      securityCardCode: 'CVV',
      byIntegratedPix: 'via Pix',
      cancelDelete: 'Não excluir',
      mercadoPago: 'Mercado Pago',
      addCard: 'Adicionar cartão',
      mercadoPagoQrCode: 'QRCode',
      byApplePay: 'via Apple Pay',
      mealVoucher: 'Vale Refeição',
      showQrCode: 'Exibir QR Code',
      howPay: 'Como deseja pagar?',
      newPayment: 'Novo pagamento',
      minimumOrder: 'Pedido mínimo',
      byGooglePay: 'via Google Pay',
      needChange: 'Preciso de troco',
      expiryDatePlaceholder: 'MM/AA',
      cardNumber: 'Número do cartão',
      cardType: 'Cartão de {{type}}',
      creditCard: 'Cartão de crédito',
      chooseFlag: 'Escolha a bandeira',
      askForChange: 'Precisa de troco?',
      changeFor: 'Troco para: {{value}}',
      addNewCard: 'Adicionar novo cartão',
      paymentMethods: 'Formas de pagamento',
      makeNewPayment: 'Fazer novo pagamento',
      deleteCard: 'Excluir cartão de {{type}}?',
      paymentLink: 'Link de $t(general.payment)',
      cardholderName: 'Nome do titular do cartão',
      onlinePayment: '$t(general.payment) online',
      onlinePaymentLink: 'Link de pagamento online',
      showPixCopyAndPaste: 'Exibir Pix Copia e Cola',
      balconyPayment: '$t(general.payment) no balcão',
      invalidNumber: 'Número inválido ou incompleto!',
      changePaymentMethod: 'Trocar forma de pagamento',
      deliveryPayment: '$t(general.payment) na entrega',
      qrCodePaymentCode: 'Código para pagamento QR Code',
      choosePaymentMethod: 'Escolher forma de pagamento',
      cardholderDocument: 'CPF/CNPJ do titular do cartão',
      errorDuringPayment: 'Ocorreu um erro durante pagamento',
      documentOnSlip: '{{documentType}} na nota: {{document}}',
      cardDeleteWarningMessage: 'Essa ação não poderá ser desfeita',
      goBackToOrder: 'Volte ao cardápio caso queira realizar um novo pedido.',
      cannotChoosePayment: 'Não foi possível escolher uma forma de pagamento',
      stepWarning: 'Por favor, não saia da tela até a conclusão do {{step}}.',
      verifyPaymentMethod: 'Verifique a forma de pagamento e tente novamente.',
      orderTimeLimitReached: 'O tempo limite para pagamento do pedido expirou.',
      invalidSecurityCardCode: 'Número de $t(payment.securityCardCode) inválido!',
      processingPaymentAndSendOrder: 'Processando pagamento e enviando pedido...',
      pleaseReviewYourData: 'Por favor, revise seus dados de pagamento e tente novamente',
      nuPayBillingMessage: 'Enviaremos uma cobrança para o Nubank com os valores deste pedido.',
      orderCancelSuccess: '<strong>O pedido foi cancelado com sucesso!</strong> Nada foi cobrado.',
      pageRefreshMessage: 'Caso já tenha realizado o pagamento, atualize a página para visualizar a confirmação.',
      paymentReceiptReminder: 'Não esqueça de enviar o comprovante para o restaurante para comprovar o pagamento.',
      choosePaymentHelp:
        'Olá, estou com problema para escolher uma forma de pagamento para o meu pedido. Pode me ajudar?',
      paymentLinkViaWhatsappMessage:
        'Nós enviaremos o link de pagamento pelo WhatsApp assim que seu pedido for confirmado!',
      autoCancelOrderMessage:
        'O pedido será cancelado automaticamente caso o pagamento não seja realizado dentro do tempo.',
      scanQrCodeMessage:
        'Escaneie o QR code que nós enviaremos junto com o seu pedido. Use os apps do Mercado Pago ou Mercado Livre.',
      applePayInfo:
        'O <strong>Apple Pay</strong> é uma maneira <strong>fácil</strong> e <strong>segura</strong> de pagar seus pedidos!',
      mpScanQrCodeMessage:
        'Escaneie o QR Code que nós enviaremos junto com o seu pedido. Use os apps do Mercado Pago ou Mercado Livre.',
      payThroughThePix:
        'Copie a chave e faça o pagamento através do Pix. O restaurante irá conferir o pagamento para liberação do seu pedido.',
      googlePayInfo:
        'Pagamento <strong>rápido, fácil</strong> e <strong>seguro</strong> é com o <strong>Google Pay</strong>. Experimente agora!',
      paymentNotConfirmedMessage:
        'Não conseguimos confirmar seu pagamento. Por questão de segurança, cancelamos o pedido. Caso tenha efetuado o pagamento solicite o estorno para o restaurante.',
      errors: {
        commonErrors: 'Veja os principais motivos que podem ter gerado o erro no seu pagamento',
        emailMustBeDifferent: 'O e-mail utilizado no pagamento não pode ser o mesmo e-mail da conta da loja'
      },
      nupay: {
        orderByNupay: 'Realize o pagamento pelo aplicativo do Nubank',
        redirectToApp:
          'Caso não apareça nenhuma notificação, toque no botão abaixo para ser direcionado ao app do Nubank',
        requirements:
          'Para usar essa forma de pagamento, você precisa ser cliente do Nubank e ter limite de crédito ou saldo em conta.',
        finishedOrderMessage:
          'Clique na notificação do Nubank que acabamos de enviar para finalizar sua compra, escolha pagar à vista ou parcelado sem juros, e confirme o pagamento.'
      },
      methods: {
        pix: 'Pix',
        nupay: 'Nubank',
        debit: 'Débito',
        cash: 'Dinheiro',
        credit: 'Crédito',
        voucher: 'Voucher',
        vrPague: 'VR Pague',
        applePay: 'Apple Pay',
        googlePay: 'Google Pay',
        mealVoucher: 'Vale Refeição',
        mPagoCheckout: 'Mercado Pago',
        onApp: 'Pagar pelo aplicativo',
        onDelivery: 'Pagar na entrega',
        mPagoQr: 'QR Code Mercado Pago',
        mPagoLink: 'Link do Mercado Pago',
        onAttendant: 'Pagar para um atendente',
        vrPagueLink: 'Link de pagamento VR Pague',
        noCreditCardRegistered: 'Nenhum cartão de crédito cadastrado',
        noPaymentCreditCard: 'Este estabelecimento não possui nenhuma forma de pagamento com cartão de crédito.'
      },
      howItWorks: {
        success: 'Pronto! A confirmação do pagamento realizado aparecerá na sua tela.',
        mpQrCode: {
          description:
            'Abra o app do Mercado Pago e escaneie o QR Code que será enviado (entrega) ou estará disponível em nossa loja (retirada).'
        },
        mpPaymentLink: {
          secure: 'O ambiente de pagamento é 100% seguro. Use seu cartão de crédito para pagar.',
          description: 'Você receberá um link assim que enviar o seu pedido. É só clicar e entrar na tela de pagamento!'
        },
        nupay: {
          finishedInApp: 'A compra será finalizada no app do Nubank',
          noCardInfoNeeded: 'Não precisa preencher os dados do seu cartão',
          noInterest: 'Parcele sua compra sem juros ou pague à vista pelo débito',
          secure: 'Pode confiar, é seguro. Sua compra é protegida pelas nossas medidas de segurança.'
        }
      },
      cardErrorList: [
        {
          title: 'Cartão novo bloqueado',
          content: 'Caso seja um cartão novo, entre em contato com o banco para desbloqueá-lo.'
        },
        {
          title: 'Cartão bloqueado para transações online',
          content: 'Entre em contato com o banco para desbloquear seu cartão e fazer transações online.'
        },
        {
          title: 'Transação não aprovada pelo banco',
          content: 'Entre em contato com o banco para entender o motivo da transação não aprovada.'
        },
        {
          title: 'Cartão sem saldo',
          content: 'Verifique se seu cartão tem limite suficiente para realizar esta transação.'
        },
        {
          title: 'Cartão vencido',
          content: 'Verifique a data de vencimento do cartão ou entre em contato com seu banco.'
        },
        {
          title: 'Código de segurança inválido',
          content: 'Verifique o código de segurança (CVV) do seu cartão localizado no seu verso.'
        }
      ],
      pix: {
        expired: 'Pix expirado',
        pixPayment: 'Pagamento Pix',
        copyAndPaste: 'Pix Copia e Cola',
        codeExpired: 'Código Pix expirado',
        codeExpiresIn: 'Este código expira em:',
        expiredPixCode: 'Seu código Pix expirou',
        touchToCopy: 'Toque para copiar o código',
        keyCopied: 'Chave Pix copiada com sucesso!',
        codeCopied: 'Código Pix copiado com sucesso!',
        countdown: 'contador regressivo para expiração do código Pix',
        receiptMessage: 'Enviaremos o comprovante do pedido no seu e-mail assim que o pagamento for aprovado.',
        confirmPaymentHelpMessage: 'Olá, gostaria de confirmar o recebimento do meu pedido via Pix. Pode me ajudar?',
        copyAndPayMessage:
          'Copie o código abaixo, pague pelo aplicativo do banco e envie o comprovante ao restaurante.',
        orderCancelSuccess:
          'O pedido foi cancelado com sucesso! Nada foi cobrado. Volte ao cardápio caso queira realizar um novo pedido.',
        copyAndPasteMessage:
          'Copie o código abaixo e utilize o Pix Copia e Cola no aplicativo de pagamento de sua preferência. Seu pedido será enviado automaticamente ao restaurante.',
        cancelOrderMessage:
          'O pedido será cancelado automaticamente caso o pagamento não seja realizado dentro do tempo acima. Caso já tenha realizado o pagamento, tente recarregar esta tela.'
      }
    }
  }
};
