import { WeekdaysShortTextEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { ReferenceId } from './general';

export interface Hour {
  open: string; // HH:mm
  close: string; // HH:mm
  to: WeekdaysShortTextEnum; // dia da semana
  from: WeekdaysShortTextEnum; // dia da semana
}

export interface PricePromotion {
  price: number;
  hash?: string;
  percentage_flag: number;
  available_hours: Hour[];
}

export interface Price {
  id?: number;
  name: string;
  price: number;
  hash?: string;
  code?: ReferenceId;
  flow_ids: number[]; // lista de id dos flows [321123, 123231]
  promotion?: PricePromotion;
}

export interface Image {
  large: string;
  small: string;
  medium: string;
}

export interface VisualFlags {
  is_vegan: boolean;
  // people_eat: number;
  is_vegetarian: boolean;
  spiciness_level: number; // 0: Nenhuma, 1, 2 ou 3: Grau de ardencia
  is_gluten_free: boolean;
  is_recommended: boolean;
  is_lactose_free: boolean;
}

export interface Product {
  id: number;
  max?: number;
  min?: number;
  name: string;
  price: number; // traz o preço único do produto ou o valor mínimo dos preços personalizados
  images: Image[];
  version: string; // usamos esse valor na rota de opcionais
  prices: Price[]; // items em abrahao
  is_combo?: boolean;
  limit_age: boolean;
  description?: string;
  is_available: boolean;
  available_hours: Hour[];
  min_price_flag: boolean; // para saber se vamos exibir o texto "a partir de" em tela
  is_cart_blocked?: boolean;
  visual_flags: VisualFlags;
  suggestions?: Omit<Product, 'suggestions'>[]; // produtos recomendados (harmonização)
}

export interface Group {
  id: number;
  name: string;
  products: Product[];
  is_available: boolean; // o que acontece quando vem false ??? - não existe esse cenário no design @maccheri
  available_hours: Hour[];
}

export interface SelectedCategoryInfo {
  id: string;
  categoryName: string;
}

export interface Menu {
  id: number;
  name: string;
  groups: Group[];
  is_main: boolean;
  icon_url: string;
  available_hours: Hour[];
}

export interface Highlight {
  name: string;
  image: Image;
  product?: Product;
  description: string;
  available_hours: Hour[];
  is_highlighted: boolean;
}

export interface Option {
  id: number;
  name: string;
  price: number;
  hash?: string; // Pendente de documentação
  image?: Image;
  flow_ids: number[]; // lista de id dos flows [321123, 123231]
  code?: ReferenceId; // remote_code em abrahao
  comboPrice?: number;
  description?: string;
  indexPosition: number;
}

// attributes (abrahao)
export interface OptionGroup {
  id: number;
  max?: number;
  min?: number;
  name: string;
  repeat?: number;
  options: Option[];
  required: boolean;
  parentFlowId?: string;
  pricing_type: OptionPricingTypeEnum;
}

export enum OptionPricingTypeEnum {
  Bigger = 'Bigger',
  Medium = 'Medium',
  Individual = 'Individual'
}

export interface PocketOrderOption {
  id: string;
  name: string;
  hash?: string;
  price: string;
  quantity: string;
  elementFlowId?: string;
  attributes?: PocketOrderOptionGroup[];
}

export interface PocketOrderOptionGroup {
  id?: string;
  options: PocketOrderOption[];
}

export interface PocketMountedOrder {
  table_number: string;
  orders: {
    card_number?: string;
    uuid: string;
    customer?: {
      name?: string;
      phone?: string;
    };
    items: {
      id: string;
      quantity: string;
      price: string;
      observation?: string;
      attributes?: PocketOrderOptionGroup[];
    }[];
  }[];
}
