import React, { useEffect, useState, useCallback, useMemo } from 'react';

import currency from 'currency.js';
import { toast } from 'react-toastify';
import { useSelector, useDispatch } from 'react-redux';
import { addSeconds, differenceInMilliseconds } from 'date-fns';
import { PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';
import { RiTodoLine, RiBankCardLine, RiMoneyDollarCircleLine } from 'react-icons/ri';

import { Empty } from '~/components/pages/MyTab';
import { IApplicationState } from '~/redux-tools/store';
import { convertToCurrency, formatPayment } from '~/utils';
import { CheckoutQrCodeIcon, LittleMachineIcon } from '~/assets';
import { cleanTabPayment } from '~/redux-tools/store/myTab/actions';
import { removePaymentPreference } from '~/redux-tools/store/user/actions';
import { cleanPixTabPayment } from '~/redux-tools/store/pixPayment/actions';
import { PixPaymentModal } from '~/components/pages/MyTabOnlinePayment/PixPaymentModal';
import { useCheckout, useFingerPrint, useGetPaymentMethodDescription, usePaymentSocket, useTranslator } from '~/hooks';
import { SelectedPaymentModeEnum, PaymentStepProcessEnum, LocalOrdersEnum, PaymentFlagEnum } from '~/interfaces/enums';
import {
  Button,
  NewWaves,
  PaymentModal,
  PaymentChosen,
  ConfirmCvvModal,
  PaymentStepModal,
  PaymentReceiptModal
} from '~/components';
import {
  TabDetails,
  TabPaymentInfo,
  TabPaymentCart,
  PaymentReceipt,
  CheckoutContextProps,
  TabPaymentDetailsResponse
} from '~/interfaces/general';
import {
  ReceiptSection,
  SectionWrapper,
  PaymentModeModal,
  SummaryValueList,
  PaymentStatusTag,
  ListReceiptModal,
  PaymentMethodCard
} from '~/components/pages/MyTabOnlinePayment';

import { RefreshTabDetailsProps } from '../myTabOnlinePayment';

import * as S from './styles';

const THIRTY_SECONDS = 30;
const TAB_REFRESH_DELAY_IN_MILLISECONDS = 5 * 1000;

export interface PricesProps {
  subtotal: number;
  valuePaid: number;
  finalPrice: number;
  totalToPay: number;
  serviceTax?: number;
  missingToBePaid?: number;
}
export interface PaymentDetailsProps {
  isShow: boolean;
  onClose: () => void;
  isServiceChargeEnabled: boolean;
  refreshTabDetails: (value: RefreshTabDetailsProps) => void;
}

export const CheckoutContext = React.createContext({} as CheckoutContextProps);

const MyTabPaymentDetails: React.FC<PaymentDetailsProps> = ({
  isShow,
  onClose,
  refreshTabDetails,
  isServiceChargeEnabled
}) => {
  const dispatch = useDispatch();

  const user = useSelector((state: IApplicationState) => state.user.data);
  const { currentPayment } = useSelector((state: IApplicationState) => state.myTab);
  const { isScriptLoaded } = useSelector((state: IApplicationState) => state.fingerPrint);
  const settings = useSelector((state: IApplicationState) => state.establishment.settings);
  const { mode: localOrder } = useSelector((state: IApplicationState) => state.localOrders);
  const { loading, tabPayment } = useSelector((state: IApplicationState) => state.pixPayment);
  const { cart, isBillPaid, payments: receipts } = useSelector((state: IApplicationState) => state.myTab.tabDetails);

  const { states, actions, context } = useCheckout();
  const { handlePayment } = actions.myTab;

  const { getPaymentMethodDescription } = useGetPaymentMethodDescription();
  const { getTranslation } = useTranslator();

  const {
    singlePaymentValue,
    selectedPaymentMode,
    setSinglePaymentValue,
    setSelectedPaymentMode,
    numberPeopleToDivision,
    setNumberPeopleToDivision,
    setHasSinglePaymentValueInputError
  } = context;

  const [isOpenReceiptsModal, setIsOpenReceiptsModal] = useState(false);
  const [showReceiptFullPage, setShowReceiptFullPage] = useState(false);
  const [isCheckedServiceCharge, setIsCheckedServiceCharge] = useState(true);
  const [isOpenPaymentModeModal, setIsOpenPaymentModeModal] = useState(false);

  const [hasRecentPayment, setHasRecentPayment] = useState(false);
  const [checkingCreditPayment, setCheckingCreditPayment] = useState(false);

  const [, setSocketEventPayment] = useState(currentPayment);
  const [, setSocketEventMissingToBePaid] = useState(0);

  useEffect(() => {
    setSocketEventPayment(currentPayment);
  }, [currentPayment]);

  const paymentPreference = useMemo(
    () => user?.paymentPreference?.find((payment) => payment.establishment === settings?.name),
    [settings, user]
  );

  useEffect(() => {
    if (!paymentPreference?.category) {
      return;
    }

    const validPayments = [PaymentCategoryEnum.integratedPix, PaymentCategoryEnum.tunaCheckout];

    if (!validPayments.includes(paymentPreference.category)) {
      dispatch(removePaymentPreference());
      return actions.order.setPaymentOption(undefined);
    }
  }, [dispatch, paymentPreference, actions.order]);

  const resolvePayment = useCallback(() => {
    dispatch(cleanTabPayment());
    dispatch(cleanPixTabPayment());

    actions.modals.setShowPaymentReceiptModal(true);
    actions.modals.setShowProcessingPaymentModal(false);
  }, [actions.modals, dispatch]);

  const calculateServiceCharge = useCallback(
    (value: number) =>
      isServiceChargeEnabled ? currency(value).multiply(cart.values.serviceTax).divide(100).value : 0,
    [cart.values.serviceTax, isServiceChargeEnabled]
  );

  const missingToBePaid = useMemo(() => {
    return currency(cart.values.subtotal).subtract(cart.values.paid).value;
  }, [cart.values.paid, cart.values.subtotal]);

  useEffect(() => {
    setSocketEventMissingToBePaid(missingToBePaid);
  }, [missingToBePaid]);

  const handleSocketEvent = useCallback(
    (eventData: TabPaymentDetailsResponse) => {
      const { code, mode, payments, total_cents } = eventData;

      let paymentToCheck: TabPaymentInfo | undefined;
      setSocketEventPayment((current) => {
        paymentToCheck = current;
        return current;
      });

      let updatedMissingToBePaid = 0;
      setSocketEventMissingToBePaid((current) => {
        updatedMissingToBePaid = current;
        return current;
      });

      setTimeout(() => {
        refreshTabDetails({ refresh: true });
      }, TAB_REFRESH_DELAY_IN_MILLISECONDS);

      if (!paymentToCheck) {
        return;
      }

      const paidPayment = payments.find(
        ({ partner_unique_id }) => partner_unique_id === paymentToCheck?.partnerUniqueID
      );

      if (paidPayment) {
        const paidValue = currency(paidPayment.value_cents, { fromCents: true }).value;

        const formattedPayment = formatPayment({
          mode,
          code: String(code),
          payment: paidPayment,
          total: currency(total_cents, { fromCents: true }).value,
          remaining: currency(updatedMissingToBePaid).subtract(paidValue).value
        });

        actions.myTab.setPaymentReceipt(formattedPayment);

        resolvePayment();
        setHasRecentPayment(true);
      }
    },
    [actions.myTab, refreshTabDetails, resolvePayment]
  );

  usePaymentSocket({ onEventAction: handleSocketEvent });

  useFingerPrint({ isScriptLoaded });

  useEffect(() => {
    if (
      !currentPayment ||
      checkingCreditPayment ||
      currentPayment.payment.category !== PaymentCategoryEnum.tunaCheckout
    ) {
      return;
    }

    const fallbackTime = addSeconds(currentPayment.date, THIRTY_SECONDS);
    const timeLeftToTriggerFallback = differenceInMilliseconds(fallbackTime, new Date());

    const creditPaymentFallback = setTimeout(() => {
      const handleCreditError = (updatedTab: TabDetails) => {
        const paidPayment = updatedTab.payments.find(
          ({ partnerUniqueID }) => partnerUniqueID === currentPayment.partnerUniqueID
        );

        if (!paidPayment) {
          actions.myTab.setHasPaymentError(true);
          actions.modals.setCvvModalConfirmed(false);
        }

        setCheckingCreditPayment(false);
        resolvePayment();
      };

      setCheckingCreditPayment(true);
      refreshTabDetails({ refresh: true, callback: handleCreditError });
    }, timeLeftToTriggerFallback);

    return () => {
      clearTimeout(creditPaymentFallback);
    };
  }, [actions.modals, actions.myTab, checkingCreditPayment, currentPayment, refreshTabDetails, resolvePayment]);

  const hasPaymentChosen = useMemo(() => {
    if (Array.isArray(user.paymentPreference) && user.paymentPreference.length > 0) {
      const userPreferencesForCurrentEstablishment = user.paymentPreference.find(
        (payment) => payment.establishment === settings?.name
      );

      if (userPreferencesForCurrentEstablishment?.category) {
        if (
          localOrder &&
          [PaymentCategoryEnum.mPagoLink, PaymentCategoryEnum.vrPagueLink].includes(
            userPreferencesForCurrentEstablishment?.category
          )
        ) {
          return false;
        }

        return true;
      }
    }

    if (context.paymentOption?.flag || context.paymentOption?.category) return true;

    return false;
  }, [user, context, settings, localOrder]);

  const handleServiceCharge = useCallback(() => {
    setIsCheckedServiceCharge(!isCheckedServiceCharge);
  }, [isCheckedServiceCharge]);

  const SelectedIcon = useMemo(() => {
    const method = context.paymentOption;

    if (method?.flag === getTranslation(PaymentFlagEnum.MercadoPagoQrCode)) return <CheckoutQrCodeIcon />;

    return <LittleMachineIcon />;
  }, [context.paymentOption, getTranslation]);

  const isUnableToConfirmCvv = useMemo(() => states.order.cvvValue.length < 3, [states.order.cvvValue.length]);

  const resetModeStates = useCallback(() => {
    setNumberPeopleToDivision(1);
    setIsOpenPaymentModeModal(false);
    setSelectedPaymentMode(SelectedPaymentModeEnum.notSelected);
    setSinglePaymentValue(0);
  }, [setNumberPeopleToDivision, setSelectedPaymentMode, setSinglePaymentValue]);

  const handleCloseModeModal = useCallback(() => {
    resetModeStates();
  }, [resetModeStates]);

  const showReceipt = useCallback(
    (receipt: PaymentReceipt) => {
      setShowReceiptFullPage(true);
      context.setShowPaymentReceiptModal(true);
      actions.myTab.setPaymentReceipt(receipt);
    },
    [actions.myTab, context]
  );

  const handleShowReceipts = useCallback(() => {
    if (receipts.length > 1) {
      return setIsOpenReceiptsModal(true);
    }

    showReceipt(receipts[0]);
  }, [receipts, showReceipt]);

  const missingSubtotal = useMemo(() => {
    if (selectedPaymentMode === SelectedPaymentModeEnum.accountDivision && !!numberPeopleToDivision) {
      return currency(cart.values.subtotal).divide(numberPeopleToDivision).value;
    }

    if (selectedPaymentMode === SelectedPaymentModeEnum.singleValue) {
      return currency(singlePaymentValue).value;
    }

    return missingToBePaid;
  }, [selectedPaymentMode, numberPeopleToDivision, missingToBePaid, cart.values.subtotal, singlePaymentValue]);

  const missingTotal = useMemo(() => {
    if (isCheckedServiceCharge) {
      const remainingServiceFee = calculateServiceCharge(missingSubtotal);

      return currency(missingSubtotal).add(remainingServiceFee).value;
    }

    return missingSubtotal;
  }, [missingSubtotal, isCheckedServiceCharge, calculateServiceCharge]);

  const renderModeModalPrices: PricesProps = useMemo(() => {
    const remainingServiceFee = calculateServiceCharge(missingSubtotal);

    return {
      finalPrice: missingTotal,
      totalToPay: missingSubtotal,
      valuePaid: cart.values.paid,
      subtotal: cart.values.subtotal,
      serviceTax: remainingServiceFee,
      missingToBePaid: missingToBePaid
    };
  }, [missingTotal, missingSubtotal, missingToBePaid, cart.values.paid, cart.values.subtotal, calculateServiceCharge]);

  const paymentValues: TabPaymentCart = useMemo(
    () => ({
      coupon: 0,
      loyalty: 0,
      deliveryFee: 0,
      takeawayDiscount: 0,
      total: missingTotal,
      subtotal: missingSubtotal,
      serviceTax: isCheckedServiceCharge ? calculateServiceCharge(missingSubtotal) : 0
    }),
    [calculateServiceCharge, isCheckedServiceCharge, missingSubtotal, missingTotal]
  );

  const renderOrderInfo = useCallback(() => {
    if (localOrder) {
      return (
        <>
          <SectionWrapper className="summary">
            <S.TitleWrapper>
              <S.SectionTitle>
                <RiTodoLine size={20} />

                <h2>{getTranslation('myTab.summaryValues')}</h2>
              </S.SectionTitle>

              {isBillPaid && <PaymentStatusTag />}
            </S.TitleWrapper>

            <SummaryValueList
              isBillPaid={isBillPaid}
              valueLeft={missingToBePaid}
              valuePaid={cart.values.paid}
              mode={getTranslation(localOrder)}
              subtotalBill={cart.values.subtotal}
              handleServiceCharge={handleServiceCharge}
              serviceChargeEnabled={isServiceChargeEnabled}
              isCheckedServiceCharge={isCheckedServiceCharge}
              serviceCharge={calculateServiceCharge(cart.values.subtotal)}
            />
          </SectionWrapper>

          <div className="waves">
            <NewWaves />
          </div>

          {receipts.length > 0 && (
            <SectionWrapper>
              <ReceiptSection quantity={receipts.length} onClick={handleShowReceipts} />
            </SectionWrapper>
          )}

          {!isBillPaid && hasRecentPayment && (
            <SectionWrapper title={getTranslation('payment.newPayment')} icon={<RiMoneyDollarCircleLine size={20} />}>
              <S.RecentPayment>
                <p className="text">{getTranslation('myTab.makeNewPayment')}</p>

                <Button
                  isGhost
                  onClick={(): void => {
                    resetModeStates();
                    setHasRecentPayment(false);
                  }}
                >
                  {getTranslation('payment.makeNewPayment')}
                </Button>
              </S.RecentPayment>
            </SectionWrapper>
          )}

          {!isBillPaid && !hasRecentPayment && (
            <>
              {localOrder === LocalOrdersEnum.table && (
                <SectionWrapper
                  hasMarginBottom={false}
                  icon={<RiMoneyDollarCircleLine size={20} />}
                  title={getTranslation('myTab.howToPay')}
                  action={
                    <Button
                      isSimple
                      onClick={resetModeStates}
                      isHidden={selectedPaymentMode === SelectedPaymentModeEnum.notSelected}
                    >
                      {getTranslation('general.change')}
                    </Button>
                  }
                >
                  {(selectedPaymentMode === SelectedPaymentModeEnum.notSelected ||
                    selectedPaymentMode === SelectedPaymentModeEnum.payFull) && (
                    <PaymentMethodCard
                      toggle={{
                        checked: selectedPaymentMode === SelectedPaymentModeEnum.payFull,
                        disabled: selectedPaymentMode === SelectedPaymentModeEnum.payFull,
                        onChange: () => setSelectedPaymentMode(SelectedPaymentModeEnum.payFull)
                      }}
                      description={
                        cart.values.paid > 0
                          ? getTranslation('myTab.payRemainingValue')
                          : getTranslation('myTab.payTotalTable')
                      }
                    />
                  )}

                  {(selectedPaymentMode === SelectedPaymentModeEnum.notSelected ||
                    selectedPaymentMode === SelectedPaymentModeEnum.accountDivision) && (
                    <PaymentMethodCard
                      price={missingTotal}
                      dividedAmount={numberPeopleToDivision}
                      description={getTranslation('myTab.divideByPeaople')}
                      isSelected={selectedPaymentMode === SelectedPaymentModeEnum.accountDivision}
                      onClick={(): void => {
                        setIsOpenPaymentModeModal(true);
                        setSelectedPaymentMode(SelectedPaymentModeEnum.accountDivision);
                      }}
                    />
                  )}

                  {(selectedPaymentMode === SelectedPaymentModeEnum.notSelected ||
                    selectedPaymentMode === SelectedPaymentModeEnum.singleValue) && (
                    <PaymentMethodCard
                      price={missingTotal}
                      description={getTranslation('myTab.enterValue')}
                      isSelected={selectedPaymentMode === SelectedPaymentModeEnum.singleValue}
                      onClick={(): void => {
                        setSelectedPaymentMode(SelectedPaymentModeEnum.singleValue);
                        setIsOpenPaymentModeModal(true);
                      }}
                    />
                  )}
                </SectionWrapper>
              )}

              <SectionWrapper title={getTranslation('payment.paymentMethods')} icon={<RiBankCardLine size={20} />}>
                {hasPaymentChosen ? (
                  <PaymentChosen
                    icon={SelectedIcon}
                    cpf={context.cpf || user.cpf}
                    title={context.paymentOption?.type || ''}
                    onClick={(): void => context.setShowPaymentOptionModal(true)}
                    isPix={context.paymentOption?.category === PaymentCategoryEnum.pix}
                    description={getPaymentMethodDescription({ paymentMethod: context?.paymentOption })}
                  />
                ) : (
                  <Button onClick={(): void => context.setShowPaymentOptionModal(true)}>
                    {getTranslation('payment.choosePaymentMethod')}
                  </Button>
                )}
              </SectionWrapper>
            </>
          )}
        </>
      );
    }

    return <Empty />;
  }, [
    context,
    user.cpf,
    isBillPaid,
    localOrder,
    SelectedIcon,
    missingTotal,
    getTranslation,
    missingToBePaid,
    receipts.length,
    resetModeStates,
    cart.values.paid,
    hasRecentPayment,
    hasPaymentChosen,
    handleShowReceipts,
    handleServiceCharge,
    selectedPaymentMode,
    cart.values.subtotal,
    isServiceChargeEnabled,
    isCheckedServiceCharge,
    calculateServiceCharge,
    numberPeopleToDivision,
    setSelectedPaymentMode,
    getPaymentMethodDescription
  ]);

  const handleConfirmModeModal = useCallback(() => {
    const isValueAboveAllowed =
      selectedPaymentMode === SelectedPaymentModeEnum.singleValue && missingSubtotal > missingToBePaid;

    if (isValueAboveAllowed) {
      setHasSinglePaymentValueInputError(true);

      return toast.error(getTranslation('myTab.singleAmount'));
    }

    setIsOpenPaymentModeModal(false);
  }, [selectedPaymentMode, missingSubtotal, missingToBePaid, setHasSinglePaymentValueInputError, getTranslation]);

  const payButtonText = useMemo(() => {
    if (isBillPaid) {
      return getTranslation('myTab.closedTable');
    }

    if (states.others.isLoading) {
      return getTranslation('order.payingOrder');
    }

    return getTranslation('myTab.valueToPay', { value: convertToCurrency(missingTotal) });
  }, [isBillPaid, states.others.isLoading, getTranslation, missingTotal]);

  const isPaymentButtonDisabled = useMemo(() => {
    if (!hasPaymentChosen || isBillPaid) {
      return true;
    }

    if (localOrder === LocalOrdersEnum.table) {
      return selectedPaymentMode === SelectedPaymentModeEnum.notSelected;
    }

    return false;
  }, [hasPaymentChosen, isBillPaid, localOrder, selectedPaymentMode]);

  return (
    <CheckoutContext.Provider value={context}>
      <S.Container isShow={isShow} onClose={onClose} isPageLike headerTitle={getTranslation('myTab.billClosing')}>
        <S.ContentWrapper>{renderOrderInfo()}</S.ContentWrapper>

        {!hasRecentPayment && (
          <S.Footer>
            <Button
              isGhost={false}
              disabled={isPaymentButtonDisabled}
              isLoading={states.others.isLoading}
              onClick={async () => handlePayment(paymentValues)}
            >
              {payButtonText}
            </Button>
          </S.Footer>
        )}
      </S.Container>

      <PaymentReceiptModal
        isPageLike={showReceiptFullPage}
        receipt={states.myTab.paymentReceipt}
        hasError={states.myTab.hasPaymentError}
        isShow={context.showPaymentReceiptModal}
        mode={getTranslation(localOrder).toLowerCase()}
        onClose={(): void => {
          setShowReceiptFullPage(false);
          context.setShowPaymentReceiptModal(false);
        }}
      />

      <ListReceiptModal
        receipts={receipts}
        showReceipt={showReceipt}
        isOpenModal={isOpenReceiptsModal}
        setIsOpenModal={(): void => setIsOpenReceiptsModal(false)}
      />

      <PaymentModal
        isMyTab
        isModalVisible={context.showPaymentOptionModal}
        closeModal={(): void => {
          context.setShowPaymentOptionModal(false);
        }}
      />

      <PaymentStepModal
        isTabPayment
        step={states.modals.processingModalType}
        isModalVisible={states.modals.showProcessingPaymentModal}
        onClose={(): void => actions.modals.setShowProcessingPaymentModal(false)}
      />

      <PixPaymentModal loading={loading} tabPayment={tabPayment} isOpenPixModal={!!tabPayment} />

      <ConfirmCvvModal
        isMyTab
        showCvvModal={states.modals.showCvvModal}
        isUnableToConfirmCvv={isUnableToConfirmCvv}
        onClose={(): void => {
          actions.modals.setShowCvvModal(false);
          actions.others.setIsLoading(false);
        }}
        onConfirm={(): void => {
          actions.others.setIsLoading(false);

          actions.modals.setProcessingModalType(PaymentStepProcessEnum.paying);
          actions.modals.setShowProcessingPaymentModal(true);
          actions.modals.setCvvModalConfirmed(true);
        }}
        actions={{
          setCvvValue: actions.order.setCvvValue,
          setIsLoading: actions.others.setIsLoading,
          setShowCvvModal: actions.modals.setShowCvvModal,
          setPaymentOption: actions.order.setPaymentOption,
          handleConfirmAction: async () => handlePayment(paymentValues),
          setShowPaymentFeedbackModal: actions.modals.setShowPaymentFeedbackModal
        }}
      />

      <PaymentModeModal
        mode={selectedPaymentMode}
        prices={renderModeModalPrices}
        onClose={handleCloseModeModal}
        showModal={isOpenPaymentModeModal}
        handleServiceCharge={handleServiceCharge}
        confirmModal={() => handleConfirmModeModal()}
        isCheckedServiceCharge={isCheckedServiceCharge}
        isServiceChargeEnabled={isServiceChargeEnabled}
        calculateServiceCharge={calculateServiceCharge}
      />
    </CheckoutContext.Provider>
  );
};

export default MyTabPaymentDetails;
