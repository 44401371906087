import { IDeliveryFeeStatic, IDeliveryFeeDynamic } from '~/interfaces/general';
import { removeNoNumbers } from '~/utils';

interface IProps {
  deliveryFee: IDeliveryFeeDynamic | IDeliveryFeeStatic;
}

const getDeliveryTime = ({ deliveryFee }: IProps) => {
  if ('hide_time' in deliveryFee && deliveryFee.hide_time) return '';

  if ('delivery_time_enabled' in deliveryFee && !deliveryFee.delivery_time_enabled) return '';

  if (!('time' in deliveryFee)) return '';

  if (typeof deliveryFee?.time === 'object') {
    const timeFrom = removeNoNumbers(deliveryFee?.time.from);
    const timeTo = removeNoNumbers(deliveryFee?.time.to);

    if (!timeFrom || timeFrom === '0') {
      if (!timeTo || timeTo === '0') return '';

      return `${timeTo} min`;
    }

    return `${timeFrom}${timeFrom === timeTo || !timeTo ? '' : ` - ${timeTo}`} min`;
  }

  return `${removeNoNumbers(deliveryFee?.time)} min`;
};
export default getDeliveryTime;
