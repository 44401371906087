import { v4 as uuidv4 } from 'uuid';
import { OperationHours } from '@goomerdev/goomer-toolbox/src/interfaces';
import { SchedulingConfig, FreemiumStatusEnum, PaymentCategoryEnum } from '@goomerdev/goomer-toolbox/src/enums';

import { CartState } from '~/redux-tools/store/cart';
import {
  InStoreEnum,
  ProductType,
  WelcomeEnum,
  OrderWayEnum,
  TabStatusEnum,
  OrderModeEnum,
  DeliveryWayEnum,
  LoginOptionEnum,
  SegmentTypeEnum,
  TunaPaymentMode,
  PaymentFlagEnum,
  LanguageCodeEnum,
  DeliveryPricingEnum,
  WaiterOptionTypeEnum,
  ReverseLocalOrdersEnum,
  TableManagerStatusEnum,
  SelectedPaymentModeEnum,
  TableManagerPaymentEnum,
  ChatbotAttendantModeEnum,
  TableManagerTabAliasModeEnum
} from '~/interfaces/enums';

import { OptionPricingTypeEnum, PricePromotion } from './Menu';

export type KeyValue = Record<string, string>;

export type ReferenceId = number | string | null;

export interface CartProductItem {
  name?: string;
  price: number;
  category?: string;
  quantity?: number;
  limit_age?: boolean;
  id: number | string;
  category_id?: number;
  description?: string;
  productReferenceID?: ReferenceId;
}

export interface CartOptionalGroup {
  id?: string;
  options: CartOptional[];
}

export interface CartOptional {
  id: number;
  name: string;
  hash?: string;
  price: number;
  quantity: number;
  subtotal?: number;
  unitPrice?: number;
  code?: ReferenceId;
  elementFlowId?: string;
  indexPosition?: number;
  optionGroupId?: number;
  optionGroupName?: string;
  isComboOptional?: boolean;
  optionals?: CartOptional[];
  attributes?: CartOptionalGroup[];
  pricingType?: OptionPricingTypeEnum;
}

export interface PriceInfo {
  id?: number;
  hash?: string;
  price: number;
  name?: string;
  code?: ReferenceId;
}

export interface CustomPriceInfo extends PriceInfo {
  name: string;
}

export interface FormattedPrice {
  price: number;
  formatted: string;
  basePrice?: number;
  promotion?: PricePromotion;
}

export interface CartProduct {
  id?: string;
  hash?: string;
  comboId?: string;
  quantity: number;
  subtotal: number;
  comment?: string;
  tabName?: string;
  code?: ReferenceId;
  customName?: string;
  selectedPriceId?: number;
  product: CartProductItem;
  optionals?: CartOptional[];
  productReferenceID?: ReferenceId;
  optionalTree?: CartOptionalGroup[];
}

export interface CartItem {
  name: string;
  comboId?: string;
  subtotal: number;
  quantity: number;
  comment?: string;
  productId?: string;
  items: CartProduct[];
}

export interface EnableRequired {
  enable: number;
  required: number;
}

export interface IField {
  type: string;
  name: string;
  limit: number;
  required: number;
}

export interface CheckoutOption {
  fee?: number;
  name: string;
  info?: string;
  field?: IField;
  value?: string;
  waiting_time?: string;
}

export interface CheckoutOptionGroup {
  name: string;
  type: string;
  options?: CheckoutOption[];
}

export interface CheckoutOptions {
  cpf: EnableRequired;
  name: EnableRequired;
  phone_number: EnableRequired;
  option_groups: CheckoutOptionGroup[];
}

export interface OptionalInputProps {
  min: number;
  max: number;
  repeat?: boolean;
  required?: boolean;
}

export interface IWorkingHour {
  to: string;
  open: string;
  from: string;
  close: string;
}

export interface IDeliveryInfo {
  delivery_waiting_time?: string;
  delivery_fee?: number | string;
  delivery_required_amount?: number;
}

export interface ResizedLogo {
  key: string;
  url: string;
}

export interface ResizedLogos {
  logo: ResizedLogo;
  logo_16x16: ResizedLogo;
  logo_32x32: ResizedLogo;
  logo_120x120: ResizedLogo;
  logo_180x180: ResizedLogo;
  logo_192x192: ResizedLogo;
  logo_300x300: ResizedLogo;
  logo_512x512: ResizedLogo;
}

export enum FieldOptionsEnum {
  optional = 'optional',
  required = 'required',
  noRequired = 'no-required'
}

export interface Address {
  cep: string;
  id?: string;
  city: string;
  state: string;
  street: string;
  number: string;
  google?: boolean;
  zipcode?: string;
  latitude?: string;
  longitude?: string;
  favorite?: boolean;
  reference?: string;

  complement?: string;
  neighborhood: string;
  neighborhood_id?: number;
  noStreetAddress?: boolean;
}

export interface ICurrency {
  id: number;
  code: string;
  label: string;
  locale: string;
  symbol: string;
  enabled: boolean;
}
export interface ITimezone {
  id: number;
  utc: string;
  name: string;
  iana: string;
  code: string;
  label: string;
  enabled: boolean;
}

export interface IDeliveryAverageTime {
  to?: number;
  from?: number;
}

export interface ICardFlags {
  name: string;
  checked: boolean;
}

export interface IDeliveryFeeStatic {
  pricing: string;
  double_check?: boolean;
  delivery_time_enabled: boolean;
  time: {
    from: number;
    to: number;
  };
}

export interface IDeliveryFeeDynamic {
  id: string;
  time: number;
  radius: number;
  status: boolean;
  pricing: string;
  hide_time: boolean;
  double_check?: boolean;
}

export interface IDeliveryFeeNeighborhood {
  id: string;
  city: string;
  status: boolean;
  pricing: string;
  hide_time?: boolean;
  neighborhood: string;
  neighborhood_id: number;
  time?: {
    from: number;
    to: number;
  };
}

export interface IEstablishmentSettings {
  id: number;
  name: string;
  slug: string;
  about: string;
  mm_a_id: string;
  address: Address;
  menu_url: string;
  store_code: number;
  description: string;
  mm_logo_key: string;
  mm_logo_url: string;
  mm_enabled: boolean;
  is_abrahao?: boolean;
  mm_main_color: string;
  mm_hours_mode: string;
  specialty_name: string;
  mm_banner_key?: string;
  mm_banner_url?: string;
  mm_always_open: string;
  mm_currency: ICurrency;
  mm_timezone: ITimezone;
  mm_delivery_fee: string;
  mm_takeaway_time: string;
  mm_store_closed: boolean;
  mm_menu_version?: string;
  mm_onsite_menu_url: string;
  mm_secondary_color: string;
  mm_coupon_enabled?: boolean;
  mm_order_show_codes: boolean;
  mm_takeaway_enabled: boolean;
  mm_takeaway_discount: string;
  mm_delivery_enabled: boolean;
  mm_facebook_pixel_id: string;
  mm_in_store_enabled: boolean;
  mm_order_mode?: OrderModeEnum;
  mm_order_show_prices: boolean;
  mm_temporarily_closed: boolean;
  mm_show_short_address: boolean;
  mm_in_store_hours_mode: string;
  mm_call_waiter_enabled: boolean;
  mm_google_analytics_id?: string;
  mm_fixed_hours?: OperationHours;
  mm_receipt_ask_for_cpf: boolean;
  mm_in_store_service_tax: number;
  mm_in_store_always_open: string;
  mm_payment_pix_enabled: boolean;
  mm_resized_logos?: ResizedLogos;
  mm_feature_pwa_enabled: boolean;
  mm_receipt_required_cpf: boolean;
  mm_splash_screen_message: string;
  mm_whatsapp_phone_number: string;
  mm_delivery_fee_enabled: boolean;
  mm_delivery_zone_options: string;
  mm_payment_cash_enabled: boolean;
  mm_in_store_modes: InStoreEnum[];
  mm_is_feedback_visible?: boolean;
  mm_languages: LanguageCodeEnum[];
  mm_is_the_check_visible: boolean;
  mm_order_scheduling_only: boolean;
  mm_in_store_store_closed: boolean;
  mm_ask_for_name: FieldOptionsEnum;
  mm_payment_debit_enabled: boolean;
  mm_free_delivery_enabled: boolean;
  mm_delivery_minimum_value: string;
  mm_whatsapp_order_enabled: boolean;
  mm_payment_credit_enabled: boolean;
  mm_ask_for_phone: FieldOptionsEnum;
  mm_waiter_options?: WaiterOption[];
  mm_operating_hours?: OperationHours;
  mm_check_manager_view_bill: boolean;
  mm_order_show_orders_count: boolean;
  mm_payment_voucher_enabled: boolean;
  mm_order_show_observations: boolean;
  mm_facebook_pixel_dns_code?: string;
  mm_feature_chatbot_enabled: boolean;
  mm_payment_pix_fee_enabled: boolean;
  mm_feature_order_v2_enabled: boolean;
  mm_order_scheduling_enabled: boolean;
  mm_payment_debit_flags: ICardFlags[];
  mm_store_enable_thirky_ads?: boolean;
  mm_google_analytics_enabled: boolean;
  mm_close_order_block_orders?: boolean;
  mm_priceless_product_enabled: boolean;
  mm_takeaway_discount_enabled: boolean;
  mm_payment_credit_flags: ICardFlags[];
  mm_free_delivery_minimum_value: string;
  mm_payment_voucher_flags: ICardFlags[];
  mm_payment_mpago_link_enabled: boolean;
  mm_suggestive_selling_enabled: boolean;
  mm_order_status_check_enabled: boolean;
  mm_payment_qrcode_debit_flags: boolean;
  mm_feedback_options?: FeedbackOption[];
  mm_theme?: 'dark' | 'light' | 'system';
  mm_close_order_closing_message?: string;
  mm_payment_qrcode_credit_flags: boolean;
  mm_payment_qrcode_cash_enabled: boolean;
  mm_in_store_enable_service_tax: boolean;
  mm_entrega_facil_ifood_enabled: boolean;
  mm_close_order_button_disabled: boolean;
  mm_payment_mpago_qrcode_enabled: boolean;
  mm_payment_vrpague_link_enabled: boolean;
  mm_in_store_fixed_hours?: OperationHours;
  mm_payment_qrcode_debit_enabled: boolean;
  mm_payment_qrcode_voucher_flags: boolean;
  mm_international_phone_enabled?: boolean;
  mm_payment_qrcode_credit_enabled: boolean;
  mm_feature_abrahao_menu_enabled?: boolean;
  mm_payment_nupay_checkout_enabled: boolean;
  mm_delivery_minimum_value_enabled: boolean;
  mm_in_store_maximum_order_distance: number;
  mm_payment_mpago_checkout_enabled: boolean;
  mm_payment_mpago_store_public_key?: string;
  mm_payment_qrcode_voucher_enabled: boolean;
  mm_multiple_menus_layout_enabled?: boolean;
  mm_feature_loyalty_program_enabled: boolean;
  mm_in_store_operating_hours?: OperationHours;
  mm_feature_customer_sign_up_enabled: boolean;
  mm_feature_gnl_tab_operation_enabled: boolean;
  mm_payment_tuna_pix_checkout_enabled: boolean;
  mm_in_store_order_show_observations?: boolean;
  mm_delivery_average_time: IDeliveryAverageTime;
  mm_order_scheduling_delivery: SchedulingConfig;
  mm_order_scheduling_takeaway: SchedulingConfig;
  mm_in_store_validate_distance_enabled?: boolean;
  mm_feature_legacy_payment_mpago_enabled: boolean;
  mm_feature_table_online_payment_enabled: boolean;
  mm_feature_qrcode_online_payment_enabled: boolean;
  mm_payment_qrcode_mpago_checkout_enabled: boolean;
  mm_payment_qrcode_nupay_checkout_enabled: boolean;
  mm_feature_loyalty_program_qrcode_enabled: boolean;
  mm_delivery_zone_options_static: IDeliveryFeeStatic;
  mm_feature_apple_pay_payment_method_enabled: boolean;
  mm_payment_qrcode_tuna_pix_checkout_enabled: boolean;
  mm_payment_tuna_credit_card_checkout_enabled: boolean;
  mm_feature_legacy_payment_manual_pix_enabled: boolean;
  mm_feature_priceless_order_blocking_enabled?: boolean;
  mm_feature_google_pay_payment_method_enabled: boolean;
  mm_delivery_zone_options_neighborhood_enabled: boolean;
  mm_delivery_zone_options_dynamic: IDeliveryFeeDynamic[];
  mm_payment_pix_info: { key: string; accountName: string };
  mm_feature_schedule_by_group_and_product_enabled?: boolean;
  mm_delivery_zone_type: 'dynamic' | 'neighborhood' | 'static';
  mm_feature_subscription_freemium_status?: FreemiumStatusEnum;
  mm_payment_qrcode_tuna_credit_card_checkout_enabled: boolean;
  mm_delivery_zone_options_neighborhood: IDeliveryFeeNeighborhood[];
  subscription_info: {
    pack_type: string;
    trial_enabled: boolean;
  };
}

export interface IScrollPos {
  x: number;
  y: number;
}

export type PaymentMethodType = 'paid_on_app' | 'paid_on_delivery' | 'paid_to_attendant';

export interface PaymentMethod {
  link?: string;
  token?: string;
  email?: string;
  cardType?: string;
  document?: string;
  cardNumber?: string;
  mpMethodId?: string;
  establishment?: string;
  type?: PaymentMethodType;
  cardDescription?: string;
  category?: PaymentCategoryEnum;
  flag?: PaymentFlagEnum | string;
  transaction_info?: boolean | object;
}

export enum CardTypeEnum {
  credit = 'mm_credit',
  debit = 'mm_debit'
}

export interface ICard {
  due: string;
  name: string;
  email: string;
  token: string;
  number: string;
  document: string;
  type: CardTypeEnum;
  mpMethodId?: string;
  flagDescription: PaymentCategoryEnum;
}

export interface CustomerLoyaltyProgram {
  total_valid_orders: number;
  last_order_at: string | null;
  first_order_at: string | null;
  reward_granted_at: string | null;
  program_fulfilled_at: string | null;
}

export interface IUser {
  cpf?: string;
  name?: string;
  email?: string;
  phone?: string;
  optIn?: boolean;
  cards?: ICard[];
  address?: boolean;
  birthday?: string;
  customerID?: string;
  addresses?: Address[];
  isFirstOrder?: boolean;
  isAuthenticated?: boolean;
  authenticationToken?: string;
  loginOptionType?: LoginOptionEnum;
  paymentPreference?: PaymentMethod[];
  customerLoyaltyProgram?: CustomerLoyaltyProgram;
}

export interface IOpenSign {
  isOpen: boolean;
}

export interface ISubscriptionForm {
  name: string;
  phone: string;
  email: string;
  password: string;
  establishment_cep: string;
  establishment_city: string;
  establishment_name: string;
  establishment_state: string;
  establishment_number: string;
  establishment_street: string;
  establishment_segment: string;
  establishment_country?: string;
  establishment_zipcode?: string;
  establishment_document: string;
  establishment_operation: string;
  establishment_complement?: string;
  establishment_neighborhood: string;
}

export interface ICardForm {
  cvv: string;
  due: string;
  name: string;
  token: string;
  email: string;
  number: string;
  document: string;
  mpMethodId?: string;
}

export interface ICoordinates {
  latitude: number;
  longitude: number;
}

export interface CheckIn {
  hash: string;
  mode: string;
  number: string;
  store_id: number;
  date: Date | null;
  square_ids?: string;
  is_abrahao?: boolean;
  isOperatorMode?: boolean;
}

export interface ILocalOrder {
  id: number;
  payment: string;
  createdAt: string;
}

export interface LocalOrder extends CheckIn {
  order: {
    id: string;
    createdAt: Date;
    payment: string;
    tabTableCode: string;
  };
}

export type DiscountType = 'absolute' | 'percentage';

export type DeliveryWay = 'delivery' | 'onsite' | 'takeaway';

export interface ICoupon {
  value: number;
  code?: string;
  name?: string;
  hours_to: string;
  enabled: boolean;
  quantity?: number;

  weekdays: number[];
  hours_from: string;
  type: DiscountType;
  order_min_value: number;

  quantity_in_use?: number;
  segment?: SegmentTypeEnum;
  available_period: string[];
  discount_max_value: number;

  order_origin: DeliveryWay[];
}

export interface OrderPayload {
  cpf: string;
  phone?: string;
  coupon?: ICoupon;
  total_value: number;
  scheduled_to?: Date;
  delivery_tax?: number;
  is_scheduled?: boolean;
  items?: CartProductItem[];
  delivery_way: DeliveryWay;
  store_coupon_code?: string;
  store_coupon_value?: number;
  payment_type?: PaymentMethod;
  payment_money_change: number;
  store_coupon_type?: DiscountType;
  establishment: string | undefined;
  scheduled_to_local_timezone?: Date;
  delivery_fee_double_check?: boolean;
  establishment_id: number | undefined;
}

export interface IOrder {
  table?: string;
  moneyChange: boolean;
  schedulingDate?: Date;
  orderWay: OrderWayEnum;
  deliveryOption?: string;
  moneyChangeValue: number;
  deliveryRate?: number | false;
  paymentOption?: PaymentMethod;
}

export const serializeOrderForGA = (order: OrderPayload) => ({
  ...order,
  currency: 'BRL',
  value: order.total_value,
  transaction_id: uuidv4(),
  coupon: order.coupon?.code,
  shipping: order.delivery_tax,
  delivery_way: order.delivery_way,
  payment_type: order.payment_type,
  items: order.items?.map((product, index) => ({
    index,
    item_id: product.id,
    price: product.price,
    item_name: product.name,
    quantity: product.quantity,
    shipping: order.delivery_tax
    // item_category: product.category
  }))
});

export interface WhatsBuilderProps {
  client: IUser;
  cart: CartState;
  orderID?: string;
  linkURL?: string;
  coupon?: ICoupon;
  withPWAInfo?: boolean;
  nupayDocument?: string;
  customMessage?: string;
  isShortMessage?: boolean;
  deliveryWay: DeliveryWay;
  moneyChangeValue?: number;
  settings: IEstablishmentSettings;
  paymentOption: PaymentMethod | string;
  schedule: {
    is_scheduled?: boolean;
    scheduled_to?: Date;
  };
}

export interface EstablishmentCheckout {
  id?: number;
  logo?: string;
  name?: string;
  store_id?: number;
  delivery_fee?: number | false;
  delivery_waiting_time: string;
  mm_in_store_service_tax?: number;
  mm_in_store_enable_service_tax?: boolean;
  loyaltyProgram?: EstablishmentLoyaltyProgram;
  mm_payment_pix_info?: { key: string; accountName: string };
  delivery_fee_mode: DeliveryPricingEnum.ask | DeliveryPricingEnum.fee | DeliveryPricingEnum.free;
  takeaway_discount?:
    | {
        type: 'percent';
        value: number;
      }
    | string;
}

export interface CheckoutUser {
  id?: string;
  email?: string;
  lastName: string;
  firstName: string;
  document?: string;
  customerID?: string;
  billingAddress?: Address | null;
  shippingAddress?: Address | null;
  phone: {
    phoneNumber: string;
    phonePrefix: string;
  };
  address?: {
    streetName: string;
    streetNumber: string;
    streetZipcode: string;
  };
}

export interface InAppCheckoutInfo {
  storeId: string;
  user: CheckoutUser;
  payment: {
    price: number;
    token: string;
    paymentMethod: string;
  };
}

export interface InAppTunaCheckoutExtraData {
  number?: string;
  brandName?: string;
  cardHolderName?: string;
  expirationYear?: number;
  expirationMonth?: number;
}

export interface InAppTunaCheckoutData {
  storeId: string;
  storeName: string;
  sessionId: string;
  user: CheckoutUser;
  tokenSession: string;
  products: CartProduct[];
  payment: {
    mode: string;
    price: number;
    token?: string;
    subtotal: number;
    data: InAppTunaCheckoutExtraData;
  };
}

export interface MountedLocalOrderInfo {
  hash: string;
  number: string;
  tab_table_code?: string;
  operation_mode: ReverseLocalOrdersEnum;
}

export interface MountedOrder {
  date: Date;
  user: IUser;
  phone: string;
  cart: CartState;
  linkURL?: string;
  version?: string;
  address?: Address;
  orderUUID?: string;
  gg_version?: string;
  order: OrderPayload;
  orderID?: number | string;
  orderCount?: number | string;
  statusCheckEnabled?: boolean;
  localOrder?: MountedLocalOrderInfo;
  establishment: EstablishmentCheckout;
  inAppCheckoutInfo?: InAppCheckoutInfo;
  inAppTunaCheckoutData?: InAppTunaCheckoutData;
  utm?: {
    source?: string;
    medium?: string;
    campaign?: string;
  };
  subscription_info?: {
    product_type: ProductType;
    pack_type?: string | undefined;
    trial_enabled?: boolean | undefined;
  };
}

export interface MountedOrderIntegratedPix extends MountedOrder {
  pixKey: string;
  qrCodePixKey: string;
}

export interface MountedNupayOrder extends MountedOrder {
  nupayPaymentUrl: string;
}

export interface TabPaymentInfo {
  date: Date;
  payment: PaymentMethod;
  partnerUniqueID: string;
}

export interface IntegratedPixTabPayment {
  pixKey: string;
  qrCodePixKey: string;
  partnerUniqueID: string;
  expiration: Date | string;
}

export type DefaultPage = 'how-it-works' | 'list' | 'selected';

export interface IStoreClosed {
  date: string;
  closed: string;
  formatted_date: { day: string; hour: string };
}

export interface PaymentReceipt {
  tax: number;
  tab?: string;
  paid: number;
  date?: string;
  total: number;
  table?: string;
  remaining?: number;
  partnerUniqueID?: string;
  mode: TableManagerPaymentEnum | TunaPaymentMode;
}

export interface CheckoutContextProps {
  cpf: string;
  moneyChange: boolean;
  discountType?: string;
  moneyChangeValue: number;
  showAddressModal: boolean;
  defaultPage?: DefaultPage;
  singlePaymentValue: number;
  showSchedulingModal: boolean;
  paymentOption?: PaymentMethod;
  numberPeopleToDivision?: number;
  paymentReceipt?: PaymentReceipt;
  showPaymentOptionModal: boolean;
  deliveryOption?: DeliveryWayEnum;
  showPaymentReceiptModal: boolean;
  schedulingDate?: Date | undefined;
  showLatLngConfirmationModal: boolean;
  setMoneyChange: (value: boolean) => void;
  hasSinglePaymentValueInputError: boolean;
  discount?: { label: string; value: number };
  setAddressSelected: (value: boolean) => void;
  setMoneyChangeValue: (value: number) => void;
  selectedPaymentMode: SelectedPaymentModeEnum;
  setShowAddressModal: (value: boolean) => void;
  setSinglePaymentValue: (value: number) => void;
  setShowSchedulingModal: (value: boolean) => void;
  setCpf: (value: string, isValid: boolean) => void;
  setPaymentOption: (value?: PaymentMethod) => void;
  setNumberPeopleToDivision: (value: number) => void;
  setDeliveryOption: (value: DeliveryWayEnum) => void;
  setShowPaymentOptionModal: (value: boolean) => void;
  setShowPaymentReceiptModal: (value: boolean) => void;
  setSchedulingDate?: (value?: Date | undefined) => void;
  setDefaultPage: (value: DefaultPage | undefined) => void;
  setShowLatLngConfirmationModal: (value: boolean) => void;
  setShouldDisplayQrCodeScannerModal: (value: boolean) => void;
  setHasSinglePaymentValueInputError: (param: boolean) => void;
  setSelectedPaymentMode: (value: SelectedPaymentModeEnum) => void;
}

export type CouponErrorType =
  | 'not_enabled'
  | 'not_found'
  | 'not_in_available_period'
  | 'not_in_defined_hours'
  | 'not_in_order_origin'
  | 'not_in_weekdays'
  | 'not_valid_order_minimum_value'
  | 'not_valid_order_value'
  | 'sold_out';

export interface CartValues {
  total: number;
  coupon: number;
  loyalty?: number;
  subtotal: number;
  service?: number;
  service_tax: number;
  delivery_fee: number;
  takeaway_discount: number;
}

export type CartValuesKeys = 'coupon' | 'delivery_fee' | 'service_tax' | 'subtotal' | 'takeaway_discount' | 'total';

export interface ErrorInfo {
  error: string;
  code?: string;
  extra?: string;
  payload: string;
  request?: string;
  message?: string;
  response?: string;
}

export type ErrorLogEntry = ErrorInfo & {
  date?: string;
  slug?: string;
  storeId?: number | undefined;
};

export interface EmailCodeAPIRequest {
  email: string;
}

export interface EmailCodeVerifyResponse {
  goomerToken?: string;
}

export interface UTMValues {
  utmId?: string;
  utmOrigin?: string;
  utmSource?: string;
  utmMedium?: string;
  utmCampaign?: string;
}

export interface RequestFunctionsProps {
  afterAction: (response: any) => void;
  addErrorLog: (log: ErrorInfo) => void;
}

export interface SendOrderResponse {
  msg: string;
  order_id: number;
  order_uuid: number;
  created_at: string;
  orders_count: number;
}

export interface ErrorResponse {
  code: string;
  reason: string;
  message: string;
}

export interface TunaBaseResponse {
  code: number;
  message: string;
}

export interface TunaGenerateResponse {
  token: string;
  brand: string;
}

export interface TunaTokenizator {
  bind: (token: string, cvv: string) => Promise<TunaBaseResponse>;
  generate: (creditCard: {
    cardNumber: string;
    expirationMonth: number;
    expirationYear: number;
    cardHolderName: string;
    cvv: string;
    singleUse: boolean;
    data: { document: string; email: string };
  }) => Promise<TunaBaseResponse & TunaGenerateResponse>;
}

export interface TunaGommerCardExtraData {
  email: string;
  document: string;
}

export interface TunaSavedCard {
  brand: string;
  token: string;
  maskedNumber: string;
  cardHolderName: string;
  expirationYear: number;
  expirationMonth: number;
  data: TunaGommerCardExtraData;
}

export interface TunaSessionData {
  token: string;
  customerId: string;
  expirationDate: number;
}

export interface HandleInAppPaymentProps {
  paymentToken: string;
  isTabPayment?: boolean;
}

export interface FingerPrintStorageProps {
  fingerPrintUserId: string;
  expireDate?: Date | string;
}

export interface HandleSubmit {
  token?: string;
  continueWithoutSignUp?: boolean;
}

export interface HandleRegistrationSubmit {
  token?: string;
  welcome?: WelcomeEnum;
  isRegisteredUser?: boolean;
  continueWithoutSignUp?: boolean;
}

export type HandlePageReloadProps = (event: BeforeUnloadEvent) => string;

export interface CustomerResponse {
  name: string;
  email: string;
  opt_in: boolean;
  birthday?: string;
  addresses: Address[];
  phone_number: string;
  is_first_order: boolean;
  customer_loyalty_program?: CustomerLoyaltyProgram;
}

export interface Channel {
  id: number;
  name: string;
}

export interface EstablishmentLoyaltyProgram {
  storeID: number;
  enabled: boolean;
  channels: Channel[];
  description: string;
  rewardDiscount: number;
  loyaltyProgramID: number;
  minimumOrderValue: number;
  daysForFulfilling: number;
  orderIntervalHours: number;
  ordersForFulfilling: number;
}

export interface PhoneNumber {
  pure: string;
  masked: string;
  withDDI: string;
}

export interface FirebaseData {
  uid: string;
  email: string;
  idToken: string;
  providerId: string;
  displayName: string;
}

export interface CancelOrderProps {
  callback?: () => void;
  orderId: number | string;
  paymentDate: Date | string;
}

export interface CityInfo {
  id: number;
  name: string;
  state: string;
}

export interface Chatbot {
  settings: {
    assistant_mode: ChatbotAttendantModeEnum;
  };
}

export interface PaymentReceiptResponse {
  _id?: string;
  created_at?: string;
  value_cents: number;
  include_tax: boolean;
  tax_value_cents: number;
  partner_unique_id?: string;
  external_payment_key?: string;
  method: TableManagerPaymentEnum | TunaPaymentMode;
}

export interface TabPaymentResponseOptional {
  _id: string;
  name: string;
  quantity: number;
  price_cents: number;
  rds_order_item_id: number;
}

export interface TabPaymentResponseOrderItem {
  _id: string;
  name: string;
  quantity: number;
  price_cents: number;
  rds_order_item_id: number;
  price_with_child_items_cents?: number;
  child_items?: TabPaymentResponseOptional[];
}

export interface TabPaymentResponseOrder {
  _id: string;
  ordered_at: string;
  service_tax: number;
  rds_order_id: number;
  items: TabPaymentResponseOrderItem[];
}

export interface TabPaymentResponseItem {
  _id: string;
  alias: string;
  orders: TabPaymentResponseOrder[];
  alias_mode: TableManagerTabAliasModeEnum;
}

export interface TabPaymentDetailsResponse {
  _id: string;
  code: number;
  store_id: number;
  mode: InStoreEnum;
  started_at: string;
  expires_at: string;
  table_code: number;
  total_cents: number;
  service_tax: number;
  total_items_quantity: number;
  tabs: TabPaymentResponseItem[];
  status: TableManagerStatusEnum;
  payments: PaymentReceiptResponse[];
}

export interface TabDetailsOrderOptional {
  name: string;
  price: number;
  quantity: number;
  optionalId: number;
}

export interface TabDetailsOrderItem {
  id: number;
  name: string;
  price: number;
  quantity: number;
  totalPrice: number;
  optionals: TabDetailsOrderOptional[];
}

export interface TabDetailsOrder {
  id: number;
  orderedAt: string;
  items: TabDetailsOrderItem[];
}

export interface TabDetailsItem {
  alias: string;
  orders: TabDetailsOrder[];
  aliasMode: TableManagerTabAliasModeEnum;
}

export interface BillExtra {
  name: string;
  price: number;
  code?: string;
  quantity: number;
  item_price?: number;
}

export interface BillItem {
  name: string;
  code: number;
  date: string;
  price: number;
  total?: number;
  quantity: number;
  tab_name?: string;
  extras: BillExtra[];
}

export interface BillAPIResponse {
  bill: BillResponse;
  status: TabStatusEnum;
  payments: PaymentReceiptResponse[];
}

export interface BillResponse {
  total: number;
  service: number;
  subtotal: number;
  discount: number;
  externalId?: number;
  products: BillItem[];
  service_tax?: number;
  status: TabStatusEnum;
}

export interface TabDetails {
  isBillPaid: boolean;
  status: TabStatusEnum;
  payments: PaymentReceipt[];
  cart: {
    products: CartProduct[];
    values: {
      paid: number;
      total: number;
      service: number;
      subtotal: number;
      serviceTax: number;
    };
  };
}

export interface TunaPaymentItem {
  name: string;
  value: number;
  category: string;
  quantity: number;
}

export interface TabPaymentCart {
  total: number;
  coupon: number;
  loyalty: number;
  subtotal: number;
  serviceTax: number;
  deliveryFee: number;
  takeawayDiscount: number;
  items?: TunaPaymentItem[];
}

export interface TunaPayloadCustomer {
  id: string;
  name: string;
  email: string;
  phonePrefix: string;
  phoneNumber: string;
  documentType: string;
  billingAddress: Address;
}

export interface TunaPayloadCart extends TabPaymentCart {
  code: number;
  sellerID: string;
  sellerName: string;
  mode: ReverseLocalOrdersEnum;
  deliveryWay: DeliveryWayEnum;
}

export interface TunaPaymentPayload {
  sessionId: string;
  tokenSession: string;
  cart: TunaPayloadCart;
  partnerUniqueID: string;
  customer: TunaPayloadCustomer;
  payment: {
    token?: string;
    mode: string;
    data?: InAppTunaCheckoutExtraData;
  };
}

export interface TunaPaymentResponse {
  id: string;
  pixKey?: string;
  message?: string;
  qrcodePixKey?: string;
  receipt?: PaymentReceipt;
}

export enum ThirkyStatusEnum {
  WithoutAds = 0,
  WithAds = 1
}

export type ThirkyMediaType = 'highlight' | 'list' | 'preRoll' | 'suggestion' | 'tabletHighlight';

export interface ThirkyMedia {
  cover: string;
  video: string | null;
  type: ThirkyMediaType;
  url_cta: string | null;
  tag_script?: string | null;
}

export interface Thirky {
  midias?: ThirkyMedia[];
  status: ThirkyStatusEnum;
}

export interface WaiterOption {
  id: number;
  name: string;
  type: WaiterOptionTypeEnum;
}

export interface OptionListItemProps extends WaiterOption {
  value: number | boolean;
}

export interface CallWaiterOption {
  id: string;
  quantity?: number;
}

export interface CallWaiterRequest {
  uuid: string;
  table_number: string;
  options: CallWaiterOption[];
}

export interface QrCodeHashReaderResult {
  date?: string;
  result?: string;
}

export interface FeedbackOption {
  id: number;
  name: string;
  icon: string;
}

export interface TabInfo {
  text: string; // QR Code hash
  number: string;
  service: string;
  name?: string | null; //QR Code nickname
  payment_link?: string | null;
}

export interface SendOrderDataCookies {
  values: CartValues;
  user: {
    cpf?: string;
    name?: string;
    phone?: string;
  };
  establishment: {
    name?: string;
    slug?: string;
    logo?: string;
  };
}
