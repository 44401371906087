import { createContext, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import {
  getAuth,
  signOut,
  AuthProvider,
  UserCredential,
  OAuthCredential,
  signInWithPopup,
  GoogleAuthProvider
} from 'firebase/auth';

import '~/lib/firebase';
import { cleanUserInfo } from '~/redux-tools/store/user/actions';

import { ModalProvider } from './modal';

const auth = getAuth();
const googleProvider: AuthProvider = new GoogleAuthProvider();

export interface AuthContextProps {
  // eslint-disable-next-line @typescript-eslint/no-invalid-void-type
  signInWithGoogle: () => Promise<UserCredential | void>;
  firebaseSignout: () => Promise<void>;
  getUserIdToken: (userCredential: UserCredential) => OAuthCredential | null;
}

export const AuthContext = createContext({
  signInWithGoogle: async () => {},
  firebaseSignout: async () => {},
  getUserIdToken: () => null
} as AuthContextProps);

const AppContext: React.FC = ({ children }) => {
  const dispatch = useDispatch();

  const getUserIdToken = (userCredential: UserCredential) => {
    return GoogleAuthProvider.credentialFromResult(userCredential);
  };

  const signInWithGoogle = async () => {
    return signInWithPopup(auth, googleProvider);
  };

  const firebaseSignout = useCallback(async () => {
    dispatch(cleanUserInfo());

    return signOut(auth);
  }, [dispatch]);

  return (
    <AuthContext.Provider
      value={{
        signInWithGoogle,
        firebaseSignout,
        getUserIdToken
      }}
    >
      <ModalProvider>{children}</ModalProvider>
    </AuthContext.Provider>
  );
};

export { AppContext };
