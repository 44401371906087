/* eslint-disable @typescript-eslint/naming-convention */
export default {
  translations: {
    languagesPage: {
      title: 'Languages'
    },
    benefits: {
      yourBenefits: 'Your benefits'
    },
    flashMessage: {
      emptyBag: 'Your bag is empty.'
    },
    suggestion: {
      advantageAndOrderToo: 'Enjoy and order too'
    },
    about: {
      aboutStore: 'About the store',
      openTwentyFour: 'Open 24 hours'
    },
    languages: {
      english: 'English',
      spanish: 'Spanish',
      portuguese: 'Portuguese'
    },
    errors: {
      orderError04: 'Something went wrong when sending the order, please try again! '
    },
    profilePage: {
      title: 'Your orders faster and more secure',
      description: 'Login and keep your information saved for this and future orders.'
    },
    offline: {
      youAreNotConnected: 'It looks like you are disconnected',
      checkYourConnection: 'Check your internet or airplane mode and try again'
    },
    product: {
      startingAt: 'From',
      card: 'Product card',
      currentlyUnavailable: 'The selected product is not available for sale at this time.'
    },
    option: {
      biggestValue: 'highest value',
      mediumValue: 'average of values',
      priceIsCalculatedBy: 'The price is calculated by the option of '
    },
    alert: {
      error: 'Error icon',
      default: 'Default icon',
      warning: 'Warning icon',
      success: 'Success icon',
      info: 'Information icon'
    },
    fullAge: {
      over18: 'Over 18?',
      declare: 'I declare that I am over 18 years old',
      prohibitedForMinors: 'This order contains items prohibited for minors'
    },
    shortWeekdays: {
      sunday: 'Sun',
      monday: 'Mon',
      friday: 'Fri',
      tuesday: 'Tue',
      saturday: 'Sat',
      thursday: 'Thu',
      wednesday: 'Wed'
    },
    detail: {
      productDetail: 'Product Detail',
      suggestionForYou: 'Suggestion for you',
      underEighteen: 'Product not intended for children under 18 years of age'
    },
    authPage: {
      title: 'Log in or register',
      description:
        'Registering makes your orders easier and can be used at any restaurant with Delivery or QR Code via Goomer!'
    },
    weekdays: {
      sunday: 'Sunday',
      monday: 'Monday',
      friday: 'Friday',
      tuesday: 'Tuesday',
      saturday: 'Saturday',
      thursday: 'Thursday',
      wednesday: 'Wednesday'
    },
    maintenance: {
      weBackSoon: "We'll be back soon!",
      inMaintenance: 'We are undergoing maintenance',
      toImproveYourExperience: 'To further improve your experience, we are adjusting some things here!'
    },
    map: {
      confirmLocation: 'Confirm location',
      confirmLocationOnMap: 'Confirm your location on the map',
      errorToGetAddressInfo: 'An error occurred while retrieving your address information. Please try again.'
    },
    paymentPromoModal: {
      news: 'News!',
      payBillFaster: 'Pay your bill faster',
      everyoneAtTheTable:
        "You and everyone at the table will be able to pay the bill at the same time on your cell phones. It's quick and easy."
    },
    appearancePage: {
      title: 'Appearance',
      darkTheme: 'Dark theme',
      lightTheme: 'Light theme',
      deviceConfig: 'Device configuration',
      deviceConfigDescription: 'The application will use the same settings as your cell phone.'
    },
    fee: {
      fee: 'Fee',
      free: 'Free',
      minute: 'min',
      toBeAgreed: 'To be agreed',
      localPickUp: 'Local pickup',
      subjectToAvailability: 'Subject to availability',
      subjectToAvailabilityAndFees: '$t(fee.subjectToAvailability) and fees'
    },
    pwaModal: {
      addInHomeScreen: 'Add Home Screen',
      addOurApp: 'Add our app to your cell phone.',
      bannerTitle: 'Have our app in the palm of your hand.',
      bannerMessage: 'Click here and save it to your home screen.',
      noDownloadNeeded:
        "<strong>You don't need to download anything, </strong> Just add our restaurant to your phone's home screen and order faster next time."
    },
    waiter: {
      tryAgain: 'Try again',
      callToWaiter: 'Call waiter',
      waiterCalled: 'A waiter was called',
      whatYouNeed: 'Tell us what you need',
      backToYouInTime: 'They will help you in a moment',
      errorToCallWaiter: 'An error occurred while calling the waiter'
    },
    optionals: {
      chooseMaxOption: 'Choose {{max}} option',
      chooseMaxOptions: 'Choose {{max}} options',
      chooseUptoMaxOption: 'Choose up to {{max}} option',
      chooseUptoMaxOptions: 'Choose up to {{max}} options',
      chooseBetweenMinAndMax: 'Choose from {{min}} to {{max}} options'
    },
    securityCardCodeModal: {
      securityCode: 'Security code',
      confirmAndSend: 'Confirm and send',
      insertSecurityCode: 'Enter the 3 or 4-digit security code (CVV) written on the back of the card',
      insertCodeAgain:
        'For security reasons, we ask that you re-enter the security code located on the back of your card.'
    },
    label: {
      addSignal: 'Plus sign',
      storeImage: 'Store image',
      subtractSignal: 'Minus sign',
      imageOf: 'Image of {{name}}',
      radioHour: 'Choose option {{label}}',
      productImage: 'Product image {{name}}',
      highlightAction: 'Featured item action',
      securityCodeModalTitle: 'Title security code'
    },
    terms: {
      termsAccept: 'I read and accept!',
      termsTitle: 'Terms and Policy of Use',
      termsAction: 'continue with registration',
      termsAgreement: 'When {{action}}, you agree with the',
      goomerAndRestaurant: 'from Goomer and the restaurant.',
      serviceAndUsageTerms: 'Terms of Service and Data Use Policy'
    },
    user: {
      change: 'Change user',
      contactInfo: 'Contact Information',
      phoneNumber: 'Your cell phone number',
      inputNamePlaceholder: 'What will we call you',
      phoneInfo:
        'The cell phone number will be used to update you on the status of your order, in addition to identifying you to speed up future orders.'
    },
    navigation: {
      more: 'More',
      menu: 'Menu',
      bill: 'Bill',
      order: 'Order',
      signIn: 'Login',
      search: 'Search',
      waiter: 'Waiter',
      profile: 'Profile',
      goToWhatsapp: 'Go to WhatsApp',
      returnToMenu: 'Return to menu',
      goToNubankApp: 'Go to Nubank app',
      returnToMyTab: 'Back to my account'
    },
    cardEntry: {
      newCard: 'New credit card',
      protectedArea: 'Protected area',
      debit: '$t(payment.debit, lowercase)',
      credit: '$t(payment.credit, lowercase)',
      editCard: 'Edit {{formattedCardType}} card',
      safePayment: '$t(general.payment) safe with:',
      invalidData: 'Invalid data. Check the data entered and try again.'
    },
    errorPage: {
      ohNo: 'AHHH NO!!',
      wrongLink: 'The link is wrong',
      pageNotFound: "We didn't find the page",
      whatHappened: 'What could have happened?',
      noProducts: 'No menus with registered products',
      errorToLoadPage: 'There was an error loading the page',
      deliveryDisabled: 'The Delivery Solution is disabled in the restaurant'
    },
    floatingFooter: {
      currentlyClosed: 'Closed at the moment!',
      unavailableProduct: 'Product unavailable!',
      notAcceptingNewOrders: 'We are currently not accepting new orders.',
      quantityLimitOnProduct: 'You have reached the maximum number of items per order',
      unavailableDueToTheTime: 'The product is currently unavailable due to the day/time.'
    },
    splash: {
      seeMenu: 'See menu',
      accessMenu: 'Access menu',
      placeAnOrder: 'Place order',
      seeProducts: 'View products',
      logInOrRegister: 'Log in or register',
      orderByTakeaway: 'Request for pickup',
      orderByDelivery: 'Order for delivery',
      loginTitle: 'Log in and order faster',
      orderInRestaurant: 'Order at the restaurant'
    },
    search: {
      searchInMenu: 'Search the menu',
      recentSearches: 'Recent searches',
      searchProducts: 'Search products',
      seeAllProducts: 'See all products',
      productNotFound: 'Product not found',
      anyResults: 'We didn\'t find any results when searching for "{{term}}"',
      productsAndCategoriesFound: 'We found {{productsQuantity}} product(s) and {{categoriesQuantity}} category(ies).'
    },
    paymentReceiptModal: {
      voucher: 'Proof',
      reviewData: 'Review data',
      backToMyAccount: 'Back to my account',
      mainReasons: 'See the main reasons that may have caused an error in your payment',
      availableInYourAccount: ' It will also be available when you return to your account. ',
      paymentReceipt: 'This is proof of your payment.{{text}}If necessary, present it to an attendant upon exit.'
    },
    ratingPage: {
      title: 'Rate',
      sendRating: 'Submit review',
      iconAriaLabel: '{{item}} review icon',
      successMessage: 'Review sent successfully',
      subtitle: 'Your opinion is very important!',
      successDescription: 'Your answer is very important to us',
      errorMessage: 'An error occurred while submitting your review',
      errorDescription: 'Check the information you entered and try again'
    },
    possibleStatus: {
      makeOrder: 'Make order',
      chooseName: 'Choose the name',
      reReadQrCode: 'Reread the QR Code',
      tableNumber: 'Enter the table number',
      insertYourDocument: 'Enter your CPF/CNPJ',
      contactNumber: 'Enter your contact number',
      choosePaymentMethod: 'Choose payment method',
      chooseScheduleDate: 'Choose an schedule date',
      deliveryAddress: 'Choose the delivery address'
    },
    callToAction: {
      logInAndSaveYourData: 'Log in and have your data saved for your next purchase!',
      logInToLoyaltyProgram: 'Sign in to participate in <strong>Loyalty Program!</strong>',
      logInAndGetFreeDelivery: 'Come in and enjoy <strong>free delivery on orders over {{minimumValue}}!</strong>',
      logInAndGetFirstOrderDiscount:
        'Enter and receive <strong>{{percentageDiscount}} off</strong> on your first store order!'
    },
    qrCodeScanner: {
      trackStart: 'Device busy',
      notReadable: 'Device busy',
      notFound: 'No devices found',
      notAllowed: 'Permission refused',
      devicesNotFound: 'No devices found',
      errorAccessingCamera: 'Error accessing the camera',
      allowAccessToCamera: 'You need to allow camera access to proceed',
      cameraError: 'There was an error with the camera. Please try again.',
      aimYourPhoneCamera: "Point your cell phone camera's <br /> at the <strong>{{qrCodeMode}}'s QR Code</strong>"
    },
    receiptInfo: {
      total: 'Total of',
      totalPaid: 'Total paid',
      progressBar: 'Progress bar',
      accountSummary: 'Account summary',
      paymentMadeOn: 'Payment made on:',
      paymentNotMade: 'Payment not made',
      successPayment: 'Payment made successfully!',
      paymentErrorAnimation: 'Payment error animation',
      paymentStatusAnimation: 'Payment status animation',
      paymentSuccessAnimation: 'Payment success animation'
    },
    clubPage: {
      informData: 'provide my details',
      createSubscription: 'Create Registration',
      subscribeToClub: 'Sign up for the Advantages Club',
      subscriptionSuccess: 'Registration completed successfully!',
      fillForm: 'Fill in the fields below to register and use the Club on your tablet.',
      clubRegisterError:
        'There was an error registering with the Advantages Club. Try starting the stream again on your tablet or speak to a support person.'
    },
    login: {
      loginSuccess: 'Login successfully',
      select: 'Select an option to continue',
      continueWithEmail: 'Continue with e-mail',
      continueWithGoogle: 'Continue with Google',
      registrationCompleted: 'Registration completed!',
      continueWithoutLogin: 'Continue without logging in',
      googleError: 'An error occurred when logging in via Google. Try again.',
      info: "You can use your login at any restaurant that uses Goomer's Delivery or QR Code. Enjoy!"
    },
    morePage: {
      about: {
        title: 'About us',
        description: 'Discover the history of our restaurant'
      },
      languages: {
        title: '$t(languagesPage.title)',
        description: 'Choose the language you prefer'
      },
      appearance: {
        title: '$t(appearancePage.title)',
        description: 'Choose between dark, light or automatic'
      },
      rating: {
        title: '$t(ratingPage.title) your experience',
        description: 'Leave a review about your experience with us'
      }
    },
    howItWorks: {
      mediumValue: 'Average price',
      biggestValue: 'Greater price',
      biggestValueDescription:
        'The final price will consider the <strong>highest value</strong> between the options you choose.',
      mediumValueDescription:
        'The final price will consider the <strong>average of values</strong> between the options you choose.',
      howItWorksExample:
        'Example: If you choose option A (R$ {{firstValue}}) and option B (R$ {{secondValue}}), <strong>the final price will be R$ {{finalValue}}.</strong>'
    },
    scheduling: {
      to: 'to',
      scheduling: 'Scheduling',
      remove: 'Delete schedule?',
      confirmTime: 'Confirm schedule',
      scheduleOrder: 'Order scheduling',
      scheduleDelivery: 'Schedule delivery',
      scheduleLocal: 'Schedule local pickup',
      approximateTime: 'Delivery times are approximate',
      closedMessage: 'We are currently closed, but you can still schedule your order!',
      nowYouCanScheduleYourOrder:
        'Now you can schedule your order through the app. Receive it at home or pick it up on the day and time you choose.'
    },
    couponMessage: {
      useInOnsiteOrder: 'use for on site orders',
      couponWasExpired: 'The coupon {{infoCode}} has expired',
      invalidCouponWeekday: 'Coupon invalid for this day of the week',
      couponOnlyValidFor: 'Coupon only valid for {{formattedMessage}}',
      couponDisabledByStore: 'This coupon has been disabled by the store!',
      couponNotFound: 'Coupon not found. Check the code and enter it again.',
      couponValidBetween: 'Coupon only valid between {{initDate}} and {{finishDate}}',
      couponNeedOrderValueOver: 'To activate the coupon, you need to have an order over {{orderValue}}'
    },
    discount: {
      applied: 'Applied',
      couponCode: 'Coupon code',
      applyCoupon: 'Apply coupon',
      insertCoupon: 'Insert coupon',
      deleteCoupon: 'Delete coupon?',
      couponApplied: 'Coupon applied',
      discountCoupon: 'Discount coupon',
      applyingCoupon: 'Applying coupon',
      loyaltyDiscount: 'Loyalty discount',
      couponAriaLabel: 'Area to insert coupon',
      percentApplied: '{{value}}% off{{text}}!',
      discountApplied: '{{value}} off{{text}}!',
      enterCouponDescription: 'Enter the coupon code below to validate your discount',
      discountMaxValue: 'This discount is being applied up to the maximum value of {{value}} of the total'
    },
    menu: {
      ofDiscount: 'discount',
      hasMinimumValue: 'for orders above {{minValue}}',
      validInLoyaltyProgram: 'Valid order in the Loyalty Program!',
      firstOrderInStore: 'First order in store? <strong>{{errorMessage}}</strong>',
      youHasDiscount: '<strong>{{name}} • </strong> You have <strong>{{discountText}} discount </strong>',
      missingValueInLoyaltyProgram: 'You need more <strong>{{value}}</strong> to join the Loyalty Program',
      youHasDiscountInFirstOrder: ' You have <strong>{{couponValue}}</strong> to use on your first order!',
      youEarnedDiscount: '<strong>Loyalty:</strong> you earned {{discountValue}} discount to use on your order!'
    },
    firstOrder: {
      registerNow: 'Register now',
      storeFirstOrder: 'First order in store?',
      firstOrderDiscount: 'First Order Discount',
      firstOrderRules: 'Open first order discount rules',
      validForOrders: 'Valid for orders of at least {{minimumValue}}.',
      registerToGetDiscount: 'Register and receive {{discountPercentage}} off!',
      shouldBeLoggedIn: 'To use the first order discount, the user must be logged in.',
      getFirstOrderDiscount: 'Earn {{discountPercentage}} off on your first order at ',
      discountInFinalOrder: 'The discount will be applied automatically when completing the order.',
      firstOrderDiscountNotCumulative: 'The first order discount cannot be combined with other discounts or coupons.'
    },
    localOrder: {
      myOrder: 'My order',
      lastName: 'and surname',
      ofClient: 'of the customer',
      tableNumber: 'Table number',
      makingOrder: 'Placing order',
      howYouWillPay: 'How will you pay',
      howToCallYou: 'What will we call you',
      localOrderTitle: 'Name {{clientName}}',
      whichIsYourTable: "What's your table?",
      orderOf: 'Order from {{modeAndNumber}}',
      enterClientName: "Enter the customer's name",
      ifWeNeedTalkToYou: 'if we need to talk to you',
      orderWithYourCurrentType: 'Do you want to place the order on the current {{orderType}}?',
      errorToGetCartData: 'There was an error fetching your cart data. Scan the QR Code and try again.',
      waiterToLocateYourTable: 'This information is useful for the waiter to locate your table upon delivery'
    },
    loggedArea: {
      cards: 'My cards',
      default: 'Logged area',
      addresses: 'My addresses',
      personalData: 'My profile',
      account: 'Account and access',
      back: 'Return to the logged in area',
      notifications: 'E-mail notifications',
      errorMessage: 'Error saving. Try again!',
      cardsDescription: 'Edit or update your cards',
      successMessage: 'Information changed successfully!',
      personalDataDescription: 'Edit or update your data',
      addressesDescription: 'Edit or update your addresses',
      notificationsDescription: 'Receive notifications directly to your e-mail',
      message: 'Your registration is valid in any restaurant with Goomer Delivery or QR Code.',
      notificationsExplanation:
        'I would like to receive emails from restaurants where I have ordered with offers, news and other communications.'
    },
    accountVerification: {
      toEmail: 'to e-mail',
      invalidCode: 'invalid. ',
      resendCode: 'Resend code',
      checkCode: 'Code {{check}}',
      resendNewCode: 'Resend code at:',
      almostThere: 'We are almost there!',
      logInOrRegister: 'Log in or register',
      insertSentCode: 'Enter the code that was sent to',
      insertYourEmail: 'Enter your e-mail below to continue',
      otherRegister: 'to another $t(general.subscription, lowercase)',
      completeYourSignUp: 'Complete your $t(general.subscription, lowercase)',
      googleLoginErrorMessage: 'An error occurred when logging in via Google. ',
      sendCodeErrorMessage: 'An error occurred while sending the code via e-mail. ',
      signUpInformation: 'This data helps us to adapt the application to your needs and preferences.',
      customerPhoneNumberInfo: 'The mobile number will be used to update you on the status of your order.',
      phoneAlreadyRegistered:
        'This phone is already linked {{validation}}. Please enter another number or go back and log in.'
    },
    information: {
      tapOn: 'Tap ',
      local: 'Location',
      safariSetting: 'aA',
      active: 'Activate ',
      settings: 'Settings',
      localization: 'Location',
      turnOnThe: 'Activate the part of ',
      websiteAdjusts: 'Website adjustments',
      websiteConfiguration: 'Website settings',
      localizationDisabled: 'Location disabled',
      makeAnOrder: 'Now just place your orders. Enjoy!',
      nextToTheWebsite: ' next to the website address in Safari',
      openSmartphoneCamera: 'Open your phones camera or QR Code app.',
      activateLocalization: 'Activate location in your browser settings!',
      scanQrcode: 'Scan the code on the table or counter in the restaurant.',
      canOrderWithGoomer: 'Now you can order with Goomer directly at the restaurant.',
      tapOnThreeDots: 'To the right of the link bar, tap the three dots icon next to',
      stepsBelowToActivate: 'Follow the steps below to activate it. We need it to find the restaurant closest to you.'
    },
    feedback: {
      newOrder: 'New Order',
      seeMyOrder: 'View my order',
      seeMyOrders: 'View my orders',
      unavailableMenu: 'Menu unavailable',
      qrcodeInactive: 'Oopss! QR Code is not active',
      orderCompleted: 'Order completed successfully!',
      soonInYourTable: 'It will soon be on your table',
      orderToKitchenSent: 'Order sent to the kitchen!',
      contactEstablishment: 'Contact the establishment',
      callAnAttendant: 'Call an attendant to guide you',
      newOrderInOrderMode: 'New order at {{orderMode}} {{number}}',
      sentOrderToKitchen: 'Order sent to the kitchen successfully!',
      orderNotSent: 'Oopss! There was a problem and the order was not sent',
      toSeeOrderDetails: 'To view order details, access your Order Manager on the Goomer panel',
      checkYourConnection: 'Check your connection and if the problem continues, call an attendant.',
      notPaidForYourOrder: 'If you have not paid for your order, go to the cashier and wait for your order.'
    },
    loyalty: {
      hour: 'hour',
      hours: 'hours',
      completeUntil: 'Complete by',
      loyaltyProgram: 'Loyalty Program',
      loyaltyProgramConcluded: 'Loyalty Program completed!',
      andParticipateOf: '$t(authPage.title) and participate of',
      validOrdersBy: 'Orders made by {{channelNames}} are valid.',
      discountEarned: 'You got a discount of {{discountValue}}, enjoy!',
      orderAndEarn: 'Order {{programOrders}} times and earn {{discountValue}}!',
      onlyValidOrders: 'Only valid and completed orders are counted in the Program.',
      useLoyaltyDiscount: 'To use the Fidelidade discount, the user must be logged in.',
      scoreWillBeConfirmedAfterBill: 'The score will be confirmed after the account is closed.',
      orderWillBeCountedInLoyaltyProgram: 'Your order will be counted towards the Loyalty Program.',
      scoreWillBeConfirmedAfterOrder: 'The score will be confirmed after the restaurant completes the order.',
      discountNotCumulative: 'The Loyalty Program discount cannot be combined with other discounts or coupons.',
      onlyValidOrdersInIntervalHours:
        'Valid Loyalty Program orders must be placed {{orderIntervalHours}} {{hours}} apart.',
      purchasesValidInProgram:
        'For purchases to be valid in the Program, the user must be logged in at the time of checkout.',
      nextOrderCanBeMadeAt:
        '<strong>Loyalty:</strong> your next valid order for the Loyalty Program can be made in {{countdown}}.',
      missingOrderValueToLoyaltyProgram:
        'There is <strong>{{remainingValue}}</strong> left for your order to be valid in the Loyalty Program.',
      autoDiscount:
        'The discount will be automatically applied to the purchase following completion of the Program and can be used within up to {{days}} days.'
    },
    address: {
      state: 'UF',
      city: 'City',
      address: 'Address',
      zipcode: 'Zip Code',
      delivery: 'Delivery',
      noAddress: 'No address',
      complement: 'Complement',
      addAddress: 'Add address',
      saveAddress: 'Save address',
      neighborhood: 'Neighborhood',
      cityNotFound: 'City not found',
      addNewAddress: 'Add new address',
      referencePoint: 'Reference point',
      confirmAddress: 'Confirm address',
      feeToBeAgreed: 'Fee to be agreed',
      deliveryAddress: 'Delivery address',
      complementPlaceholder: 'House, apt',
      addressNotFound: 'Address not found',
      selectAnAddress: 'Select an address',
      confirmingAddress: 'Confirming address',
      approximateDelivery: 'Approx. delivery',
      noAddressAdded: 'No addresses added yet',
      whatYourAddress: 'What is your address?',
      tooFarMessage: "Wow, you're so far away!",
      dontHaveZipcode: "I don't know my zip code",
      cityPlaceholder: 'Enter the name of the city',
      neighborhoodNotFound: 'No neighborhoods found',
      zipcodeNotFound: '$t(address.zipcode) not found',
      streetNameLabel: 'Street, avenue, square, alley',
      deliveryTimeAndTax: 'View delivery time and tax',
      selectAddressToDelivery: 'Select a delivery address',
      tooFarDescription: 'Address outside our delivery area',
      streetNameOrZipcode: 'Street name or $t(address.zipcode)',
      selectAnAddressDescription: 'to see delivery time and fee',
      fetchCityError: 'Error when searching for city suggestions',
      addAddressMessage: 'Add an address to see delivery times and fees',
      selectAnAddressToDelivery: '$t(address.selectAnAddress) for delivery',
      enterStreetNameOrZipcode: 'Enter the $t(address.streetNameOrZipcode)',
      addressNotFoundContactStore: 'Address not found. Please contact the Store!',
      editAndRemoveInstructions: 'Swipe an address (right to edit or left to delete) or add a new address.',
      fetchAddressError: 'We are having difficulty finding your $t(address.zipcode), enter the data manually'
    },
    myTab: {
      available: 'available',
      payMyBill: 'Pay my bill',
      totalTable: 'Table total',
      enterValue: 'Enter value',
      valueToPay: 'Pay {{value}}',
      missingToPay: 'Need to pay',
      closedTable: 'Closed table',
      accountPaid: 'Paid account',
      requestBill: 'Request bill',
      availablePlural: 'available',
      amountToPay: 'Amount to pay',
      totalOf: 'Total of {{mode}}',
      howToPay: 'How will you pay',
      leaveReview: 'Leave a review',
      billClosing: 'Account closure',
      accountSummary: 'Account Summary',
      summaryValues: 'Summary of values',
      subtotalOf: 'Subtotal of {{mode}}',
      requestedBill: 'Account requested',
      divideByPeaople: 'Divide by people',
      pricePerPerson: 'Price per person:',
      noOrderSoFar: 'No orders placed yet',
      waitingForPayment: 'Awaiting payment',
      payTotalTable: 'Pay the table in full',
      paymentViaDashboard: 'Payment via panel',
      payRemainingValue: 'Pay remaining amount',
      bringUpBill: 'An attendant will bring your bill',
      notOrderedYet: "You haven't placed an order yet",
      dontLikeToLeaveReview: "I don't want to review ",
      checkboxServiceTax: 'Checkbox for service charge',
      checkboxServiceCharge: 'Checkbox for service charge',
      billWasPaid: 'The bill has been paid and your list is empty.',
      anotherLookInMenu: 'How about taking another look at our menu?',
      backToAccountTryAgain: 'Go back to your account and try again.',
      processingAccount: 'We are processing your account, please wait',
      callAnAttendantToCloseBill: 'To close the account, call an attendant',
      itemsWillAppearHere: 'Place an order and your items will appear here',
      likeToLeaveReview: 'Would you like to leave a review about our establishment?',
      singleAmount: 'The single amount cannot be greater than the remaining amount.',
      reopenAccount: 'The total bill was paid and closed. To reopen it, place a new order',
      howMuchYouWantToPay: 'Enter how much you want to pay from the total bill for this table',
      makeNewPayment: 'A recent payment was made at this table. Do you want to make a new payment?',
      usePixCodeInYourApp:
        'Copy the code below and use the <strong>Pix Copy and Paste</strong> in your payment app. Wait for the <strong>receipt confirming</strong> your payment to appear.'
    },
    order: {
      and: 'and',
      myOrder: 'My order',
      between: 'View order',
      prepare: 'Preparation',
      viewOrder: 'View order',
      lastOrder: 'Last order',
      tryAgain: 'Order again',
      orderSent: 'Order sent!',
      orderReady: 'Order ready!',
      cancelOrder: 'Cancel order',
      orderStatus: 'Order status',
      orderDetail: 'Order details',
      orderPending: 'Pending order',
      addMoreItem: 'Add more items',
      sendOrder: 'Order submission',
      sendingOrder: 'Sending order',
      orderExpired: 'Order expired',
      confirmOrder: 'Confirm order',
      payingOrder: 'Making payment',
      orderCanceled: 'Order canceled',
      orderTracking: 'Order tracking',
      inPreparation: 'In preparation!',
      sendYourOrder: 'send your order',
      orderConfirmed: 'Order confirmed',
      paymentPending: 'Payment pending',
      cancelingOrder: 'Canceling order',
      paymentReceived: 'Payment received',
      scheduleReady: 'All done! {{ready}}',
      mercadoPagoLink: 'Mercado Pago Link',
      sendViaWhatsapp: 'Send via WhatsApp',
      willBeDelivered: 'will be delivered:',
      waitingPayment: 'Order awaiting payment',
      payAndMakeOrder: 'Pay and place the order',
      successIllustration: 'Success illustration',
      scheduleOrderTime: 'The order {{available}}',
      orderPreparing: 'The order is being prepared',
      receiveNotifications: 'Receive notifications',
      orderTotalValue: 'Total order value: {{value}}',
      orderCanBeTaken: 'The order can now be picked up!',
      watchAnimation: 'Animation of a clock ticking away',
      sorryForInconvenience: 'Sorry for the inconvenience.',
      orderWentOutForDelivery: 'The order is out for delivery!',
      waitingStoreConfirmation: 'Waiting for store confirmation',
      willBeAvailableToTakeaway: 'will be available for pickup:',
      checkOrderDetails: 'See details of your last order below.',
      noOrderStatusAvailable: 'No orders in progress at the moment',
      cancelOrderQuestion: 'Do you want to cancel the entire order?',
      problemSendingOrder: 'Problems sending the order via WhatsApp?',
      acceptedByStoreFeedback: 'Order feedback accepted by the store!',
      minimumValueNotMet: 'Value reported lower than the order value.',
      scheduledTakeaway: 'Your pickup has been scheduled successfully!',
      restaurantCanceledOrder: 'The restaurant has canceled your order.',
      scheduledDelivery: 'Your delivery has been scheduled successfully!',
      redirectingToSafeEnvironment: 'Redirecting to a secure environment',
      statusWillAppearHere: 'Place an order and follow its progress here',
      cancelOrderWarning: 'By canceling, all your progress will be lost.',
      expectedDelivery: 'Estimated delivery <strong>{{waitingTime}}</strong>',
      expectedTakeaway: 'Estimated pickup time <strong>{{waitingTime}}</strong>',
      whatsappUpdatesMessage: 'You will receive updates on your order via WhatsApp!',
      zeroedOrderMessage: 'The order value must be greater than the subtotal {{value}} to proceed.',
      valueSmallerThanLoyalty: 'The order subtotal amount {{value}} needs to be greater than the loyalty discount.',
      cannotConfirmOrderInfo:
        'We were unable to confirm your order details. Contact the restaurant to speed up your service.'
    },
    errorMessage: {
      invalidCoupon: 'Invalid coupon.',
      tabInfoError: 'Error getting tab info.',
      checkCvvCardCode: 'Check the security code',
      checkTheCardNumber: 'Check the card number',
      checkCardDetails: 'Check the card details.',
      expiredCoupon: 'The coupon used has expired.',
      invalidCouponHour: 'Coupon invalid for this time.',
      checkExpiryDate: 'Check the expiry date of the card',
      invalidProductsInOrder: 'Invalid products in the order.',
      pleaseChoosePaymentMethod: 'Please choose a payment method.',
      orderRejectedByMercadoPago: 'Order rejected by Mercado Pago.',
      storeIdNotFound: 'We couldnt find the store ID. Please try again.',
      errorToSaveAddress: 'Error saving the address used in this order.',
      couponDisabledByStore: 'This coupon has been disabled by the store.',
      invalidCouponForThisOrder: 'Invalid coupon for the value of this order',
      errorToCloseBill: 'Something went wrong to close your bill. Please try again.',
      checkYourPhoneNumber: 'Check that your cell phone number is correct and try again.',
      errorToGetBill: 'Something went wrong when searching for your bill. Please try again.',
      belowMinimumValue: 'The order is below the minimum delivery price for this restaurant.',
      amountOfChange: 'The amount of change cannot be less than the total value of the order',
      errorToSendOrder: 'Something went wrong when sending the order. Please refresh the page!',
      storeNotAcceptingWithoutSchedule: 'This store is not accepting orders without an schedule.',
      errorToMakePayment: 'Something went wrong when making the payment. Please reload the page.',
      errorToFindLocation: 'Something went wrong when searching for a location, please try again',
      tableNumberNotFound: 'A table with this number was not found. Check the number and try again.',
      mercadoPagoMinimumValue: 'The value of transactions with Mercado Pago must be at least R$0,50',
      minimumPixOrderValue: 'The minimum order with Pix is R$ 20,00. Choose another payment method.',
      notBeGenerated: 'The payment link could not be generated. Please choose another payment method.',
      searchingDeliveryFee: 'An error occurred when searching for the delivery fee. Please try again.',
      errorGettingTableInfo: 'Something went wrong when searching for the table info. Please try again.',
      checkCardDetailsContactStore: 'Check the card details provided, if the problem persists contact us.',
      errorToSendOrderCheckData: 'Something went wrong when sending the order. Check the data and try again.',
      emailConfirmationExceeded: 'Maximum number of email confirmations exceeded, try again in a few seconds.',
      errorToGenerateMercadoPagoLink: 'Something went wrong when generating the payment link with Mercado Pago',
      errorToCheckYourLogin: 'Something went wrong when checking your login via {{loginOptionMode}}. Try again.',
      errorToGetOptionals: 'Something went wrong when searching for product options. Please try again. (Error #10)',
      browserDoesntSupportGeolocation:
        'Your browser does not support geolocation, please try again in another browser.',
      errorToGenerateLink:
        'Something went wrong when generating the Mercado Pago link, please try again or use another payment method',
      yourBillHasBeenRequested:
        'We were unable to send the request because your bill has already been requested. Talk to an attendant.',
      notAllowDirectPayment:
        'Delivery with a fee to be agreed does not allow direct payment via the App. Please choose another payment method.',
      errorToSendOrderWithSchedule:
        'Something went wrong when sending the order due to the time of the appointment, please select the time again',
      checkTheData:
        'Something went wrong when sending the order, please check the data and try again. If the error persists, please contact the store.'
    },
    general: {
      or: 'or',
      to: 'to',
      and: 'and',
      cpf: 'CPF',
      new: 'New',
      key: 'Key',
      tab: 'Tab',
      add: 'Add',
      days: 'days',
      copy: 'Copy',
      from: 'from',
      name: 'Name',
      paid: 'Paid',
      cnpj: 'CNPJ',
      exit: 'Exit',
      type: 'Type',
      skip: 'Skip',
      help: 'Help',
      edit: 'Edit',
      free: 'Free',
      back: 'Back',
      card: 'Card',
      view: 'View',
      open: 'Open',
      daySuffix: '',
      today: 'Today',
      table: 'Table',
      price: 'Price',
      items: 'Items',
      total: 'Total',
      rules: 'Rules',
      order: 'Order',
      close: 'Close',
      phone: 'Phone',
      again: 'again',
      later: 'Later',
      email: 'E-mail',
      signIn: 'Enter',
      google: 'Google',
      closed: 'Closed',
      coupon: 'Coupon',
      number: 'Number',
      change: 'Change',
      remove: 'Delete',
      cancel: 'Cancel',
      greeting: 'Hello',
      qrCode: 'QR Code',
      balcony: 'Counter',
      loyalty: 'Loyalty',
      receipt: 'Receipt',
      payment: 'Payment',
      confirm: 'Confirm',
      showAll: 'See all',
      address: 'Address',
      signUp: 'Register',
      invalid: 'Invalid',
      sending: 'Sending',
      product: 'Product',
      required: 'Required',
      showMore: 'See more',
      subtotal: 'Subtotal',
      discount: 'Discount',
      optional: 'Optional',
      category: 'Category',
      removing: 'Deleting',
      continue: 'Continue',
      showLess: 'See less',
      gotIt: 'I understood',
      editItem: 'Edit item',
      everyDay: 'Every day',
      copyCode: 'Copy code',
      tryAgain: 'Try again',
      viewOnly: 'View only',
      typeHere: 'Type here',
      confirmed: 'Confirmed',
      noThanks: 'No, thanks',
      myAccount: 'My account',
      categories: 'Categories',
      scheduling: 'Scheduling',
      takeaway: 'Local pickup',
      okThanks: 'Okay, thanks',
      yesRemove: 'Yes, delete',
      yesCancel: 'Yes, cancel',
      validUntil: 'Valid until',
      unavailable: 'Unavailable',
      reviewData: 'Review data',
      firstOrder: 'First order',
      serviceTax: 'Service fee',
      removeItem: 'Delete item',
      inOrder: ' on the invoice',
      consumption: 'Consumption',
      okayGotIt: 'Okay, got it!',
      seeDetails: 'View details',
      invalidDate: 'Invalid date',
      deliveryTax: 'Delivery fee',
      saveChanges: 'Save changes',
      waitMoment: 'Wait a moment',
      subscription: 'Subscription',
      confirmation: 'Confirmation',
      observations: 'Observations',
      stillToBePaid: 'Need to pay',
      backToMenu: 'Return to menu',
      orderSummary: 'Order summary',
      okayLetsGo: "Okay, let's go!",
      invalidField: 'Invalid field',
      invalidValue: 'Invalid value',
      anExperience: 'An experience',
      birthdayDate: 'Date of birth',
      loadingImage: 'Loading image',
      workingHours: 'Opening hours',
      onTheBalcony: 'On the balcony',
      totalWithTax: 'Total with fee',
      principalMenu: 'Principal menu',
      startChat: 'Start conversation',
      invalidEmail: 'Invalid e-mail!',
      chooseOption: 'Choose an option',
      subtotalCoupon: 'Coupon subtotal',
      emailExample: 'name@email.com.br',
      insertYourName: 'Enter name here',
      addObservation: 'Add observation',
      contactStore: 'Contact the store',
      chooseOneOption: 'Choose 1 option',
      howDoesItWork: 'How does it work?',
      reportAProblem: 'Report a problem',
      houseSuggestion: 'House suggestion',
      discountOfType: 'Discount {{type}}',
      otherCategories: 'Other categories',
      itemAddedToCart: 'Item added to bag',
      subtotalDiscount: 'Discount subtotal',
      totalWithDiscount: 'Total discounted',
      orderDetails: '$t(general.order) details',
      successfullyCopied: 'Successfully copied!',
      insertDocument: 'Enter your CPF/CNPJ here',
      myOrder: 'My $t(general.order, lowercase)',
      paymentWays: '$t(general.payment) Methods',
      bitFarAway: 'You seem to be a bit far away',
      firstNameAndLastName: 'First and last name',
      discountForTakeaway: 'Discount for pick up',
      optionsLimitReached: 'Options limit reached!',
      enterAndParticipate: 'Log in and participate',
      paymentMethods: '$t(general.payment) methods',
      subtotalWithDiscount: 'Subtotal with discount',
      documentInOrder: '{{document}} in the invoice: ',
      paymentConfirmed: '$t(general.payment) confirmed',
      paymentConfirm: '$t(general.payment) confirmation',
      enterYourPhoneNumber: 'Enter your cell phone number',
      earnDiscount: '{{discountPercentage}}% discount!',
      cancelPayment: 'Cancel $t(general.payment, lowercase)',
      invalidBirthdayDate: '$t(general.birthdayDate) invalid',
      namePlaceholder: 'Enter your $t(general.name, lowercase)',
      enterYourEmail: 'Inform your $t(general.email, lowercase)',
      successConfigMessage: 'Configuration changed successfully!',
      emailPlaceholder: 'Enter your $t(general.email, lowercase)',
      subtotalWithLoyaltyDiscount: 'Subtotal with loyalty discount',
      paymentNotFinished: '$t(general.payment, lowercase) not made',
      shouldInsertDocument: 'You need to inform your {{- document}}',
      invalidDocument: '{{- document}} $t(general.invalid, lowercase)',
      enterValidPhoneNumber: 'Please provide a valid cell phone number',
      changePaymentMethod: 'Change $t(general.payment, lowercase) method',
      toUseTheApp: 'To use the app you need to be near the establishment',
      contactStoreForHelp: 'Please contact the store to resolve this issue.',
      enterYourNameAndLastName: 'Enter your first and last name (no abbreviations)',
      observationPlaceholder: 'Enter the $t(general.observations, lowercase) here...',
      freeDeliveryMinimumValue: '<strong>Free delivery</strong> on orders over {{value}}!',
      minimumValue: 'Order <strong>minimum of {{value}}</strong> for delivery. Delivery fee not included.',
      whatsappSendReminder: "Remember to send your order via WhatsApp to the restaurant. It's easy and fast!",
      observationMessage:
        'Talk directly to the establishment if you want to modify any item. Modifications that could generate additional charges are not accepted in this field.'
    },
    payment: {
      debit: 'Debt',
      money: 'Cash',
      credit: 'Credit',
      pixKey: 'Pix Key',
      useCard: 'Use card',
      addCard: 'Add card',
      byNubank: 'via Nubank',
      securityCardCode: 'CVV',
      newPayment: 'New payment',
      cardNumber: 'Card number',
      creditCard: 'Credit card',
      addNewCard: 'Add new card',
      byIntegratedPix: 'via Pix',
      byApplePay: 'via Apple Pay',
      mercadoPago: 'Mercado Pago',
      mealVoucher: 'Meal Voucher',
      needChange: 'I need change',
      mercadoPagoQrCode: 'QRCode',
      askForChange: 'Need change?',
      byGooglePay: 'via Google Pay',
      expiryDate: 'Expiration date',
      cancelDelete: 'Do not delete',
      showQrCode: 'Display QR Code',
      chooseFlag: 'Choose the flag',
      minimumOrder: 'Minimum order',
      expiryDatePlaceholder: 'MM/YY',
      howPay: 'How do you want to pay?',
      paymentMethods: 'Payment methods',
      cardholderName: 'Cardholder name',
      changeFor: 'Change for: {{value}}',
      makeNewPayment: 'Make new payment',
      deleteCard: 'Delete {{type}} card?',
      paymentLink: '$t(general.payment) link',
      onlinePaymentLink: 'Link to online payment',
      choosePaymentMethod: 'Choose payment method',
      changePaymentMethod: 'Change payment method',
      qrCodePaymentCode: 'Code for QR Code payment',
      invalidNumber: 'Invalid or incomplete number!',
      cardType: '{{type, capitalizeFirstLetter}} card',
      cardholderDocument: 'CPF/CNPJ of the cardholder',
      showPixCopyAndPaste: 'Display Pix Copy and Paste',
      deliveryPayment: '$t(general.payment) on delivery',
      balconyPayment: '$t(general.payment) at the counter',
      onlinePayment: 'Online $t(general.payment, lowercase)',
      errorDuringPayment: 'An error occurred during payment',
      cannotChoosePayment: 'Unable to choose a payment method',
      cardDeleteWarningMessage: 'This action cannot be undone',
      documentOnSlip: '{{documentType}} in the invoice: {{document}}',
      verifyPaymentMethod: 'Check your payment method and try again.',
      goBackToOrder: 'Return to the menu if you want to place a new order.',
      invalidSecurityCardCode: 'Invalid $t(payment.securityCardCode) number!',
      pleaseReviewYourData: 'Please review your payment details and try again',
      processingPaymentAndSendOrder: 'Processing payment and sending order...',
      orderTimeLimitReached: 'The time limit for paying for the order has expired.',
      stepWarning: 'Please do not leave the screen until the completion of the {{step}}.',
      nuPayBillingMessage: 'We will send a charge to Nubank with the amounts of this order.',
      paymentReceiptReminder: "Don't forget to send the receipt to the restaurant to prove payment.",
      orderCancelSuccess: '<strong>The order was canceled successfully!</strong> Nothing was charged.',
      pageRefreshMessage: 'If you have already made the payment, refresh the page to view confirmation.',
      autoCancelOrderMessage: 'The order will be automatically canceled if payment is not made on time.',
      choosePaymentHelp: "Hello, I'm having trouble choosing a payment method for my order. Can you help me?",
      paymentLinkViaWhatsappMessage:
        'We will send you the payment link via WhatsApp as soon as your order is confirmed!',
      scanQrCodeMessage:
        'Scan the QR code that we will send with your order. Use the Mercado Pago or Mercado Livre apps.',
      mpScanQrCodeMessage:
        'Scan the QR Code that we will send with your order. Use the Mercado Pago or Mercado Livre apps.',
      payThroughThePix:
        'Copy the key and make the payment via Pix. The restaurant will check the payment to release your order.',
      googlePayInfo:
        '<strong>Fast, easy</strong> and <strong>secure</strong>payment with <strong>Google Pay</strong>. Try it now!',
      applePayInfo:
        '<strong>Apple Pay</strong> is an <strong>easy</strong> and <strong>secure</strong> way to pay for your orders!',
      paymentNotConfirmedMessage:
        'We were unable to confirm your payment. For security reasons, we canceled the order. If you have made payment, request a refund from the restaurant.',
      errors: {
        commonErrors: 'See the main reasons that may have caused an error in your payment',
        emailMustBeDifferent: 'The e-mail used for payment cannot be the same e-mail as the store account'
      },
      nupay: {
        orderByNupay: 'Make payment through the Nubank app',
        redirectToApp: 'If no notification appears, tap the button below to be directed to the Nubank app',
        requirements:
          'To use this payment method, you need to be a Nubank customer and have a credit limit or account balance.',
        finishedOrderMessage:
          'Click on the Nubank notification we just sent to finalize your purchase, choose to pay in cash or interest-free installments, and confirm the payment.'
      },
      methods: {
        pix: 'Pix',
        cash: 'Cash',
        debit: 'Debt',
        nupay: 'Nubank',
        credit: 'Credit',
        voucher: 'Voucher',
        vrPague: 'VR Pague',
        onApp: 'Pay by app',
        applePay: 'Apple Pay',
        googlePay: 'Google Pay',
        mealVoucher: 'Meal Voucher',
        onDelivery: 'Pay on delivery',
        mPagoCheckout: 'Mercado Pago',
        mPagoLink: 'Mercado Pago link',
        mPagoQr: 'QR Code Mercado Pago',
        onAttendant: 'Pay for an attendant',
        vrPagueLink: 'VR Pague payment link',
        noCreditCardRegistered: 'No credit card registered',
        noPaymentCreditCard: 'This establishment does not have any form of credit card payment.'
      },
      howItWorks: {
        success: 'Ready! Confirmation of the payment made will appear on your screen.',
        mpQrCode: {
          description:
            'Open the Mercado Pago app and scan the QR Code that will be sent (delivery) or available in our store (pick up).'
        },
        mpPaymentLink: {
          secure: 'The payment environment is 100% secure. Use your credit card to pay.',
          description: 'You will receive a link once you submit your order. Just click and enter the payment screen!'
        },
        nupay: {
          noCardInfoNeeded: 'No need to fill in your card details',
          finishedInApp: 'The purchase will be completed in the Nubank app',
          noInterest: 'Pay your purchase in interest-free installments or pay in cash via debit',
          secure: "You can trust, it's safe. Your purchase is protected by our security measures."
        }
      },
      cardErrorList: [
        {
          title: 'New card blocked',
          content: 'If it is a new card, contact the bank to unblock it.'
        },
        {
          title: 'Card blocked for online transactions',
          content: 'Contact your bank to unblock your card and make online transactions.'
        },
        {
          title: 'Transaction not approved by the bank',
          content: 'Contact your bank to understand the reason for the unapproved transaction.'
        },
        {
          title: 'Card without balance',
          content: 'Check if your card has enough limit to carry out this transaction.'
        },
        {
          title: 'Expired card',
          content: "Check the card's expiration date or contact your bank."
        },
        {
          title: 'Invalid security code',
          content: "Check your card's security code (CVV) located on the back."
        }
      ],
      pix: {
        expired: 'Expired Pix',
        pixPayment: 'Pix Payment',
        touchToCopy: 'Tap to copy code',
        codeExpired: 'Expired Pix code',
        copyAndPaste: 'Pix Copy and Paste',
        codeExpiresIn: 'This code expires on:',
        keyCopied: 'Pix key copied successfully!',
        codeCopied: 'Pix code copied successfully!',
        expiredPixCode: 'Your Pix code has expired',
        countdown: 'countdown timer for Pix code expiration',
        receiptMessage: 'We will send proof of order to your e-mail as soon as payment is approved.',
        confirmPaymentHelpMessage: 'Hello, I would like to confirm receipt of my order via Pix. Pode me ajudar?',
        copyAndPayMessage: 'Copy the code below, pay through the bank app and send the receipt to the restaurant.',
        orderCancelSuccess:
          'The order was canceled successfully! Nothing was charged. Return to the menu if you want to place a new order.',
        copyAndPasteMessage:
          'Copy the code below and use Pix Copia e Cola in the payment app of your choice. Your order will be automatically sent to the restaurant.',
        cancelOrderMessage:
          'The order will be automatically canceled if payment is not made within the above time. If you have already made the payment, try reloading this screen.'
      }
    }
  }
};
